
import React from "react";
import { Slide } from "react-slideshow-image";

import client10 from "../../assets/img/clients/client-10.jpg";
import client8 from "../../assets/img/clients/client-8.png"
// import client5 from "../../assets/img/clients/client-5.png"
import client6 from "../../assets/img/clients/client-6.png";
// import client7 from "../../assets/img/clients/client-7.png"
import client9 from "../../assets/img/clients/client-9.png";

import "react-slideshow-image/dist/styles.css";
import SectionHeader from "../../shared/SectionHeader";


const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 4
};

const responsiveSettings = [

    // {
    //     breakpoint: 800,
    //     settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3
    //     }
    // },
    // {
    //     breakpoint: 500,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //     }
    // }
    
];

function ClientsSection() {

    return (

        // < !-- ======= Clients Section ======= -->

        <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">

                <SectionHeader
                    title="Our Partners"
                    description="Here are some of the companies we are in close connection with. Some are our partners, some our clients and some our products. All of them have supported us."
                />

                <div className="clients-slider swiper">
                    <div className="client-slide-wrapper">
                        <Slide easing="ease" {...properties} responsive={responsiveSettings}>
                            {/* <div className="swiper-slide"><img src={client7} className="img-fluid" alt="" /></div> */}
                            <div className="swiper-slide"><img src={client8} className="img-fluid" alt="" /></div>
                            <div className="swiper-slide"><img src={client9} className="img-fluid" alt="" /></div>
                            <div className="swiper-slide"><img src={client10} className="img-fluid" alt="" /></div>
                            {/* <div className="swiper-slide"><img src={client5} className="img-fluid" alt="" /></div> */}
                            <div className="swiper-slide"><img src={client6} className="img-fluid" alt="" /></div>

                            {/* <div className="swiper-slide"><img src={client8} className="img-fluid" alt="" /></div> */}
                        </Slide>
                    </div>
                </div>

            </div>
        </section>

        //  {/* <!--End Clients Section-- > */ }
    );


}

export default ClientsSection;
