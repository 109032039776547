
import React from "react";
import { Link } from "react-router-dom";


function WhyUsSection() {

    return (

        //   < !-- ======= Why Us Section ======= -->
        <section id="why-us" className="why-us">
            <div className="container">

                <div className="row">
                    <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                        <div className="content border-box-curve">
                            <h3>Your city's parking solution</h3>
                            <p>
                                {/* Our goal is to provide safe, orderly, and fair parking for employees, students, and visitors to Kampala. We strive to make City parking on as convenient as possible, while promoting safe movement of vehicles, providing for pedestrian safety. */}

                                Our aim is to provide smooth traffic flow through the city center while improving accessibility to the nearest parking facility, promoting mobility, harmony, comfortability within the city center and ensuring safety from unavoidable accidents.

                                {/* Kampala City parking control, Preventing congestion, promoting mobility with in the city center and ensuring peace, harmony and comfortability within the city center. */}
                            </p>
                            <div className="text-center">
                                <Link to="/about" onClick={() => window.scrollTo(0, 0)} className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 d-flex">
                        <div className="icon-boxes d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                    <div className="icon-box mt-4 mt-xl-0">
                                        <i className="bx bxs-car"></i>
                                        <h4>Street Parking</h4>
                                        <p>Multiplex Limited is here to make parking user friendly, convenient, affordable, diversified and flexible...</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                                    <div className="icon-box mt-4 mt-xl-0">
                                        <i className="bx bxs-receipt"></i>
                                        <h4>Virtual Tickets</h4>
                                        <p>Multiplex Limited is doing away with physical tickets and turning to virtal tickets. A better payment...</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                                    <div className="icon-box mt-4 mt-xl-0">
                                        <i className="bx bxs-cube-alt"></i>
                                        <h4>Payment Modes</h4>
                                        <p>Multiplex Limited is offering more payment modes for its clients not to stree with payment...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        // <!--End Why Us Section-- >

    );

}

export default WhyUsSection;
