import React from "react";
import Footer from "../../shared/Footer";

import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
import PrivacyLinks from "../../Components/PrivacySection/PrivacyLinks";
import PrivacyContent from "../../Components/PrivacySection/PrivacyContent";



class PrivacyPolicy extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Privacy Policy"
                        back="Home"
                        backHref="/"
                    />

                    <section id="project-details" className="project-details">
                        <div className="container" data-aos="fade-up" data-aos-delay="100">

                            <div className="row justify-content-between gy-1 mt-1">

                                <PrivacyContent />

                                <PrivacyLinks />

                            </div>

                        </div>
                    </section>
                </main>
                <Footer />


            </div>
        )
    }
}

export default PrivacyPolicy;
