import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/img/logo.svg";



function Header() {

    const [hideNav, setHideNav] = useState(true);
    const [activeLocationPath] = useState(window.location.pathname);
    const [activeRoot, setActiveRoot] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        setPath();
    });

    const setPath = () => {
        const pathSections = activeLocationPath.split("/");
        setActiveRoot("/" + pathSections[1])
    }

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    // const construction = () => {
    //     window.open("http://www.multiplexug.net/");
    // }

    // const sidopress = () => {
    //     window.open("http://sidopresssolutions.com/");
    // }

    // const ssezibwa = () => {
    //     window.open("https://www.ssezibwafalls.com/");
    // }

    // const thrivetec = () => {
    //     window.open("https://twitter.com/SsezibwaFalls");
    // }


    return (

        <header id="header" className={hideNav ? "shadow-box d-flex align-items-center" : "fixed-top shadow-box d-flex align-items-center"}>
            <div className="container d-flex align-items-center justify-content-between">

                <div className="logo">
                    <Link to="/"><img src={logo} alt="" className="img-fluid" /></Link>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/' && 'active'}`} to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                        </li>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/about' && 'active'}`} to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link>
                        </li>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/services' && 'active'}`} to="/services" onClick={() => window.scrollTo(0, 0)}>Services</Link>
                        </li>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/careers' && 'active'}`} to="/careers" onClick={() => window.scrollTo(0, 0)}>Careers</Link>
                        </li>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/payments' && 'active'}`} to="/payments" onClick={() => window.scrollTo(0, 0)}>Payments</Link>
                        </li>
                        {/* <li className="dropdown">
                            <Link to="#"><span>Multiplex-Products</span> <i className="bi bi-chevron-down"></i>
                            </Link>
                            <ul>
                                <li>
                                    <Link to="#" onClick={construction}>Construction</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={sidopress}>SidoPress</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={ssezibwa}>Ssezibwa project</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={thrivetec}>Thrivetecug</Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/map' && 'active'}`} to="/map" onClick={() => window.scrollTo(0, 0)}>Parking-Map</Link>
                        </li> */}
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/enforcement' && 'active'}`} to="/enforcement" onClick={() => window.scrollTo(0, 0)}>Enforcement</Link>
                        </li>
                        <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/construction' && 'active'}`} to="/construction" onClick={() => window.scrollTo(0, 0)}>Construction</Link>
                        </li>
                        {/* <li>
                            <Link className={`nav-link scrollto ${activeRoot === '/contact' && 'active'}`} to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
                        </li> */}

                        {/* <li><Link to="blog.html">Blog</Link></li> */}
                        {/* <li className="dropdown"><Link to="#"><span>Policies</span> <i className="bi bi-chevron-down"></i></Link>
                            <ul>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="#">Terms and conditions</Link></li>
                                <li className="dropdown"><Link to="#"><span> Drop Down 2 </span> <i className="bi bi-chevron-right"></i></Link>
                                    <ul>
                                        <li><Link to="#">Deep Drop Down 1</Link></li>
                                        <li><Link to="/privacy-policy">Deep Drop Down 2</Link></li>
                                        <li><Link to="#">Deep Drop Down 3</Link></li>
                                        <li><Link to="#">Deep Drop Down 4</Link></li>
                                        <li><Link to="#">Deep Drop Down 5</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="#">Drop Down 3</Link></li>
                                <li><Link to="#">Drop Down 4</Link></li>
                            </ul>
                        </li> */}
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </header>

    );

}

export default Header;
