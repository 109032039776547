
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Fade } from "react-slideshow-image";

import slide1 from "../../assets/img/hero-bg1.jpg";
import slide2 from "../../assets/img/plexpark.jpg";
import slide3 from "../../assets/img/20220830_081508.jpg";
import slide4 from "../../assets/img/plexed.jpg";
// import slide5 from "../../assets/img/hero-bg5.png";
import slide6 from "../../assets/img/20220830_080932.jpg";

import "react-slideshow-image/dist/styles.css";


const properties = {
    duration: 7000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false
};


function Slider() {

    const [hideNav, setHideNav] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    return (
        <>
            {/* //    < !-- ======= Hero Section ======= --> */}
            {/* <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                <div className="container" data-aos="fade-in">
                    <h1>Welcome to Multiplex Limited</h1>
                    <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, purpose and concept in mind to meet public satisfaction and needs.</h2>
                    <div className="d-flex align-items-center">
                        <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                        <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                    </div>
                </div>
            </section> */}

            {/* <section id="slider" className="slid-container d-flex flex-column justify-content-center align-items-center" >
                <Fade easing="ease">
                    {slideImages.map((slide, index) => {
                        return (
                            <div className="slide" key={slide}>
                                <div style={{ backgroundImage: `url(${slideImages[index]})` }}>
                                    <span>Slide {index + 1}</span>
                                </div>
                            </div>
                        );
                    })}
                </Fade>
            </section> */}

            <div className={hideNav ? "slide-container" : "scrolled-offset slide-container"}>

            {/* background-image: linear-gradient(to right, #00000036, #0000007a), url(../images/bg3.jpg); */}

                <Fade easing="ease" {...properties}>
                    <div className="each-slide">
                        <section style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${slide1})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Parking</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, and purpose in mind to meet public satisfaction and needs.</h2>
                                {/* <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div> */}
                            </div>
                        </section>
                    </div>

                    <div className="each-slide">
                        <section style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${slide2})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Parking</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, and purpose in mind to meet public satisfaction and needs.</h2>
                                {/* <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div> */}
                            </div>
                        </section>
                    </div>

                    <div className="each-slide">
                        <section style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${slide3})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Parking</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, and purpose in mind to meet public satisfaction and needs.</h2>
                                {/* <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div> */}
                            </div>
                        </section>
                    </div>

                    <div className="each-slide">
                        <section style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${slide4})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Parking</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, and purpose in mind to meet public satisfaction and needs.</h2>
                                {/* <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div> */}
                            </div>
                        </section>
                    </div>

                    <div className="each-slide">
                        <section style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${slide6})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Parking</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, and purpose in mind to meet public satisfaction and needs.</h2>
                                {/* <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div> */}
                            </div>
                        </section>
                    </div>

                    {/* <div className="each-slide">
                        <section style={{ backgroundImage: `url(${slide5})` }} id="hero" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="container" data-aos="fade-in">
                                <h1>Welcome to Multiplex Limited</h1>
                                <h2>Here to make parking user friendly, convenient, affordable, diversified and flexible with consistence, purpose and concept in mind to meet public satisfaction and needs.</h2>
                                <div className="d-flex align-items-center">
                                    <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                                    <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
                                </div>
                            </div>
                        </section>
                    </div> */}
                </Fade>

            </div>
        </>

    );

}

export default Slider;
