import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import TeamSection from "../../Components/TeamSection/TeamSection";
// import CounterSection from "../../Components/AboutSection/CounterSection";
// import AboutSection from "../../Components/AboutSection/AboutSection";
// import ClientsSection from "../../Components/ClientsSection/ClientsSection";
import BlogSection from "../../Components/BlogSection/BlogSection";
// import ServicesSection from "../../Components/ServicesSection/ServicesSection";



class BlogPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Blog"
                        back="Home"
                        backHref="/"
                    />

                    <BlogSection />
                    {/* <CounterSection />
                    <ClientsSection /> */}
                    {/* <ServicesSection /> */}
                    {/* <TeamSection /> */}

                </main>
                <Footer />

            </div>
        )
    }
}

export default BlogPage;
