import React from "react";
import SectionHeader from "../../shared/SectionHeader";


function ServicesSection() {

    return (
        // < !-- ======= Services Section ======= -->
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">

                <SectionHeader
                    title="Services"
                    description="Multiplex Limited endeavours to give its clients, customers and partners the best service it can avail and here are a few of the services Multiplex offers."
                />

                <div className="row gy-4">

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="service-item  position-relative">
                            <div className="icon">
                                <i className="bx bxs-car"></i>
                            </div>
                            <h3>Parking Space Monitoring</h3>
                            <p>Multiplex Limited is here to make parking user friendly, convenient, affordable, diversified and flexible... This involves real-time monitoring of parking spaces using the enhansed system to detect occupancy and availability of parking spots</p>
                            {/* <a href="/services" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-parking"></i>
                            </div>
                            <h3>Parking Reservation</h3>
                            <p>Multiplex Limited helps Users to reserve parking spaces in advance through the enhanced online system or our very own Plex mobile app, ensuring they have a guaranteed spot upon arrival</p>
                            {/* <a href="/services" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-receipt"></i>
                            </div>
                            <h3>Payment Processing</h3>
                            <p>Multiplex Limited has created a system that facilitate various payment methods, including cash, credit/debit cards, mobile payments, and contactless payments</p>
                            {/* <a href="/payments" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-map"></i>
                            </div>
                            <h3>Parking Guidance</h3>
                            <p>Multiplex parking system offers guidance capabilities that provide drivers with real-time information about available parking spots within a parking facility, reducing the time spent searching for a space</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-report"></i>
                            </div>
                            <h3>Reporting and Analytics</h3>
                            <p>Our clients can now easily get any information about their parking, parking usage, charges, occupancy rates, and other key metrics to help them during the parking process</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-chip"></i>
                            </div>
                            <h3>Integration with Other Systems</h3>
                            <p>Parking management systems can integrate with other systems such as transportation networks, payment gateways, and smart city platforms to provide seamless services and enhance overall efficiency</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-user-voice"></i>
                            </div>
                            <h3>Customer Support</h3>
                            <p>Multiplex parking provides assistance to users, resolving issues, and offering support through various channels such as helplines, chatbots, or in-person assistance</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-traffic-barrier"></i>
                            </div>
                            <h3>Enforcement</h3>
                            <p>Our Parking management systems often include tools for enforcing parking rules and regulations, such as issuing tickets or penalties for violations</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bx bxs-analyse"></i>
                            </div>
                            <h3>Parking slot demacation</h3>
                            <p>We provide parking spaces by demacating parking areas alongside the road and creating spaces for parking for our clients</p>
                            {/* <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> */}
                        </div>
                    </div>

                </div>



                {/* <div className="section-title" data-aos="fade-up">
                    <h2>Services</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div> */}

                {/* <div className="row">
                    <div className="col-lg-4 col-md-6" data-aos="fade-up">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-briefcase"></i></div>
                            <h4 className="title"><Link to="">Lorem Ipsum</Link></h4>
                            <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-card-checklist"></i></div>
                            <h4 className="title"><Link to="">Dolor Sitema</Link></h4>
                            <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-bar-chart"></i></div>
                            <h4 className="title"><Link to="">Sed ut perspiciatis</Link></h4>
                            <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-binoculars"></i></div>
                            <h4 className="title"><Link to="">Magni Dolores</Link></h4>
                            <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-brightness-high"></i></div>
                            <h4 className="title"><Link to="">Nemo Enim</Link></h4>
                            <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                            <div className="icon"><i className="bi bi-calendar4-week"></i></div>
                            <h4 className="title"><Link to="">Eiusmod Tempor</Link></h4>
                            <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
                        </div>
                    </div>
                </div> */}

            </div>
        </section>
        // <!--End Services Section-- >

    );

}

export default ServicesSection;
