import React from "react";
// import { Link } from "react-router-dom";
// import { Slide } from "react-slideshow-image";

// import slide from "../../assets/img/plex/7.png";

// import slide1 from "../../assets/img/plex/1.png";
// import slide2 from "../../assets/img/plex/2.png";
// import slide3 from "../../assets/img/plex/3.png";
// import slide4 from "../../assets/img/plex/4.png";
// import slide5 from "../../assets/img/plex/5.png";
// import slide6 from "../../assets/img/plex/6.png";
// import logo7 from "../../assets/img/plex/phone.svg";

// import slide7 from "../../assets/img/vids/1.mp4";
// import slide8 from "../../assets/img/vids/2.mp4";
import slide9 from "../../assets/img/vids/5.mp4";
// import slide10 from "../../assets/img/vids/4.png";

import frame from "../../assets/img/plex/phone.svg";
import frame2 from "../../assets/img/plex/plex2.gif";

import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import plex from '../../assets/img/plex/Plex Website Design-19.png';


// const properties = {
//     duration: 15000,
//     transitionDuration: 1500,
//     infinite: true,
//     indicators: false,
//     arrows: false,
//     pauseOnHover: false
// };

const plex = () => {
    // window.open("https://plex.multiplexstreetparking.com/");
    window.open("https://plex-parking.netlify.app");
}


function PlexSection() {

    return (

        <>

            {/* <section id="about" className="about section-bg">

                <div className="container">

                    <SectionHeader
                        title="Introducing Plex"
                        description="Enim quis est voluptatibus aliquid consequatur fugiat"
                    />

                    <div className="row">



                        <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
                            <video
                            src={video}
                            className='video-section'
                            autoPlay
                            loop
                            muted
                        >

                        </video>

                            <img src={plex} className='video-section' alt="plex" />
                            <Link to="#" className="glightbox play-btn mb-4"></Link>
                        </div>


                        <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">



                            <h4 data-aos="fade-up">About us</h4>
                        <h3 data-aos="fade-up">Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                        <p data-aos="fade-up">Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam et sed minima temporibus laudantium. Soluta voluptate sed facere corporis dolores excepturi. Libero laboriosam sint et id nulla tenetur. Suscipit aut voluptate.</p>

                            <div className="icon-box" data-aos="fade-up">
                                <div className="icon"><i className="bx bx-fingerprint"></i></div>
                                <h4 className="title"><Link to="">Lorem Ipsum</Link></h4>
                                <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="bx bx-gift"></i></div>
                                <h4 className="title"><Link to="">Nemo Enim</Link></h4>
                                <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><i className="bx bx-atom"></i></div>
                                <h4 className="title"><Link to="">Dine Pad</Link></h4>
                                <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p>
                            </div>

                        </div>
                    </div>

                </div>
            </section> */}

            {/* <!-- ======= Alt Services Section 2 ======= --> */}
            <section id="alt-services-2" className="alt-services">
                <div className="container" data-aos="fade-up">

                    <div className="row justify-content-around gy-4">

                        <div className="col-lg-5 d-flex flex-column justify-content-center">
                            <h3>Introducing the Plex App</h3>
                            <p>Parking just got better. Plex parking is our new solution to all things parking in Kampala. </p>

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                <i className="bx bxs-parking flex-shrink-0"></i>
                                <div>
                                    <h4><Link to="#" className="stretched-link point plex-cursor">Find Parking</Link></h4>
                                    <p>Plex app is bringing parking closer to you just by allowing you to personally find where you want to park, check out if there is any available parking, then go and park</p>
                                </div>
                            </div>

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                <i className="bx bxs-card flex-shrink-0"></i>
                                <div>
                                    <h4><Link to="#" className="stretched-link point">Virtual Tickets</Link></h4>
                                    <p>After a great day of parking, Plex has made payment as easy as a click away. No more need to look for tickes around town, now virtual... or even better pay with mobile money.</p>
                                </div>
                            </div>

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                <i className="bx bxs-car flex-shrink-0"></i>
                                <div>
                                    <h4><Link to="#" className="stretched-link point">Link all your cars</Link></h4>
                                    <p>Do you have a number of cars? If yes, Plex now offers the ability to link all your cars in one app and track each car's parking progress, activity and transactions...</p>
                                </div>
                            </div>

                            <div className="mt-5" data-aos="fade-up">
                                <div className="box">
                                    <div className="btn-wrap">
                                        <Link to="#" onClick={plex} className="mt-5 readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                <i className="bi bi-brightness-high flex-shrink-0"></i>
                                <div>
                                    <h4><Link to="#" className="stretched-link">Tride clov</Link></h4>
                                    <p>Est voluptatem labore deleniti quis a delectus et. Saepe dolorem libero sit non aspernatur odit amet. Et eligendi</p>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className="col-lg-6 img-bg video-box">
                            <Slide easing="ease" {...properties}>
                                <div className="swiper-slide"><img src={slide1} className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src={slide2} className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src={slide3} className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src={slide4} className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src={slide5} className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src={slide6} className="img-fluid" alt="" /></div>
                            </Slide>
                        </div> */}

                        <div className="col-lg-6">

                            <div className="mx-auto plex-img" data-aos="zoom-in" data-aos-delay="100">
                                {/* <img className="mb-4" src={logo7} alt="plex-logo" /> */}

                                {/* <img className="mb-4" src={logo7} alt="plex-logo" style={{ width: "450px",  marginLeft: "650px", marginBottom: "" }} /> */}

                                {/* <video
                                    src={slide9}
                                    className='w-100'
                                    autoPlay
                                    loop
                                    muted
                                >

                                </video> */}

                                <div className="d-flex">

                                    {/* <img 
                                        className="img-fluid" 
                                        style={{ 
                                            // width: "325px", 
                                            zIndex: "5",
                                            // border: "1px solid #000",
                                            // marginLeft: "57.1px" 
                                        }}
                                        src={frame} 
                                        alt="" 
                                    />

                                    <video
                                        src={slide9}
                                        className="img-fluid"
                                        style={{
                                            width: "287px",
                                            margin: "113px 0 0 12px",
                                            position: "absolute",
                                            borderRadius: "17px"
                                        }}
                                        autoPlay
                                        loop
                                        muted
                                    >

                                    </video> */}

                                    <img
                                        className="img-fluid"
                                        src={frame2}
                                        alt=""
                                    />

                                </div>
                            </div>

                            {/* <Slide easing="ease" {...properties}> */}

                            {/* <div className="img-bg" data-aos="zoom-in" data-aos-delay="100">
                                    <video
                                        src={slide9}
                                        className='w-50'
                                        autoPlay
                                        loop
                                        muted
                                    >

                                    </video>
                                </div> */}

                            {/* <video
                                    // src={video}
                                    src="https://www.youtube.com/shorts/8zGL-OPUMgA"
                                    controlsList="nodownload"
                                    className='videobg'
                                    autoPlay
                                    loop
                                    muted
                                >
                                
                                </video> */}

                            {/* <div className="img-bg" data-aos="zoom-in" data-aos-delay="100">
                                    <video
                                        src={slide8}
                                        className='w-100'
                                        autoPlay
                                        loop
                                        muted
                                    >

                                    </video>
                                </div>

                                <div className="img-bg" data-aos="zoom-in" data-aos-delay="100">
                                    <video
                                        src={slide7}
                                        className='w-100 h-100'
                                        autoPlay
                                        loop
                                        muted
                                    >

                                    </video>
                                </div> */}


                            {/* <div className="img-bg" style={{ backgroundImage: `url(${slide1})` }} data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="img-bg" style={{ backgroundImage: `url(${slide2})` }} data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="img-bg" style={{ backgroundImage: `url(${slide3})` }} data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="img-bg" style={{ backgroundImage: `url(${slide4})` }} data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="img-bg" style={{ backgroundImage: `url(${slide5})` }} data-aos="zoom-in" data-aos-delay="100"></div> 
                                <div className="img-bg" style={{ backgroundImage: `url(${slide6})` }} data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="img-bg" style={{ backgroundImage: `url(${slide10})` }} data-aos="zoom-in" data-aos-delay="100"></div> */}
                            {/* </Slide> */}

                            {/* <div className="mt-5"></div>

                            <div className="mt-5" data-aos="fade-up">
                                <div className="box">
                                    <div className="btn-wrap">
                                        <a href="/plex" className="mt-5 readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>



                </div>
            </section>
        </>

    );

}

export default PlexSection;
