
import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../shared/SectionHeader";



function PricingSection({ button }) {

    return (
        // < !-- ======= Pricing Section ======= -->
        <section id="pricing" className="pricing">
            <div className="container">

                <SectionHeader
                    title="Parking Price"
                    description="Below is our pricing criteria for parking locations, slots and our reservation areas around the streets of Kampala and any other Multiplex operated area."
                />

                {/* <div className="section-title">
                    <h2 data-aos="fade-up">Pricing</h2>
                    <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div> */}

                <div className="row">

                    <div className="col-lg-3 col-md-6" data-aos="fade-up">
                        <div className="box">
                            <h3>Parking</h3>
                            <h4><sup>Shs</sup>1,000<span> / hr</span></h4>
                            <ul>
                                <li>Pay Shs 800 after 2 hour parking</li>
                                <li>Notified when parking</li>
                                <li>Pay with various methods</li>
                                {/* <li className="na">Pharetra massa</li>
                                <li className="na">Massa ultricies mi</li> */}
                            </ul>
                            {button &&
                                <div className="btn-wrap">
                                    <Link to="/payments" onClick={() => window.scrollTo(0, 0)} className="btn-buy">Check It Out</Link>
                                </div>
                            }
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                        <div className="box">
                            <h3>Sticker</h3>
                            <h4><sup>Shs</sup>55,000<span> / sticker</span></h4>
                            <ul>
                                <li>Payable with various methods</li>
                                <li>Duration date is 2 weeks</li>
                                <li>Applicable on all types of cars</li>
                                {/* <li>Pharetra massa</li>
                                <li>Massa ultricies mi</li> */}
                            </ul>
                            {button &&
                                <div className="btn-wrap">
                                    <Link to="/payments" onClick={() => window.scrollTo(0, 0)} className="btn-buy">Check It Out</Link>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
                        <div className="box featured">
                            <h3>Reservation</h3>
                            <h4><sup>Shs</sup>300,000<span> / month</span></h4>
                            <ul>
                                <li>Wide space variety provided</li>
                                <li>Private and Governement access</li>
                                <li>Payable with various methods</li>
                                {/* <li>Pharetra massa</li>
                                <li className="na">Massa ultricies mi</li> */}
                            </ul>
                            {button &&
                                <div className="btn-wrap">
                                    <Link to="/payments" onClick={() => window.scrollTo(0, 0)} className="btn-buy btn-reserve">Check It Out</Link>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                        <div className="box">
                            <span className="advanced">Advanced</span>
                            <h3>Ultimate</h3>
                            <h4>
                                <sup>Negotiable</sup><span> / month</span>
                            </h4>
                            <ul>
                                <li>Private parking service</li>
                                <li>Access to the PMI System</li>
                                <li>Control personlized parking</li>
                                {/* <li>Pharetra massa</li>
                                <li>Massa ultricies mi</li> */}
                            </ul>
                            {button &&
                                <div className="btn-wrap">
                                    <Link to="/contact" onClick={() => window.scrollTo(0, 0)} className="btn-buy">Contact Office</Link>
                                </div>
                            }
                        </div>
                    </div>

                </div>

            </div>
        </section>
        // <!--End Pricing Section-- >

    );

}

export default PricingSection;
