import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

import functions from '../../utils/functions';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';

import {
    // withScriptjs,
    // withGoogleMap,
    useJsApiLoader,
    GoogleMap,
    Marker,
    InfoWindow,
    DirectionsRenderer,
    // Polyline,
    AutoComplete,
    // Search
} from '@react-google-maps/api';

import FullTopNav from '../../Components/HeaderSection/FullTopNav';
import ActivityLoader from '../../shared/ActivityLoader';
import Footer from '../../shared/Footer';




const center = { lat: 0.314622, lng: 32.578276 }

const mapStyles = {
    // position: "relative",
    width: "100%",
    height: "70vh",
    // border: "1px solid red",
    // zIndex: "1"
};

function ParkingMap() {

    const [hideNav, setHideNav] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: dictionary._apiKey,
        // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    })

    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()

    if (!isLoaded) {
        return <ActivityLoader />
    }

    async function calculateRoute() {
        if (originRef.current.value === '' || destiantionRef.current.value === '') {
            return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: originRef.current.value,
            destination: destiantionRef.current.value,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
    }

    function clearRoute() {
        setDirectionsResponse(null)
        setDistance('')
        setDuration('')
        originRef.current.value = ''
        destiantionRef.current.value = ''
    }

    return (

        <div>
            <FullTopNav />

            <main id="main">

                <div className={hideNav ? "" : "scrolled-offset"}>
                    <GoogleMap
                        center={center}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '90vh' }}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onLoad={map => setMap(map)}
                    >
                        <Marker position={center} />
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                </div>

            </main>
            <Footer />
        </div>

    );


}

export default ParkingMap;
