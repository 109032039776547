import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import ContactSection from "../../Components/ContactSection/ContactSection";
// import Notfoundpage from "../../shared/404";
// import NotFound from "../../shared/404";

import structure from '../../assets/img/structure.jpg';



class EnforcementPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Enforcement"
                        back="Home"
                        backHref="/"
                    />

                    <section id="contact" className="contact">
                        <div className="container">
                            {/* <NotFound /> */}

                            <h2>On street parking enforcement</h2>

                            <p>
                                Our enforcement team patrols all parking restricted areas of the Kampala City and issue penalty to parking violators. This reduces dangerous parking, improves traffic flow and helps to keep the Kampala City Organized.
                                <br />
                                <br />
                                To avoid a parking ticket:
                            </p>

                            <ol>
                                <li>Check the signs and lines where you park</li>
                                <li>Park within a permitted bay and make sure the whole vehicle is in the parking bay</li>
                                <li>Don’t park on pavements or Restricted areas because other people have</li>
                                <li>Don’t assume you can stop for a short time</li>
                                <li>Always get assistance from our parking control attendants</li>
                            </ol>

                            <h3>Penalty charges</h3>

                            <p>
                                The person who owns the vehicle is responsible for all unpaid penalty charges.
                                <br />
                                <br />

                                <ol>
                                <li>40,000 ugx  for Illegal parking contraventions e.g. parking on  pavements , corners  and other restricted areas, double parking among other violations as mentioned above.</li>
                                <li>75,000 ugx for all vehicles that have been clamped and later towed to our offices.</li>
                                <li>2,000 shillings as surcharge for vehicles that fail to pay for their parking invoices within 48 hours.</li>
                                </ol>
                            </p>

                            <img src={structure} alt="structure"/>

                            <br />
                            <br />

                            <h3>New Street Parking structure</h3>

                            <ol>
                                <li>Dastrol street left hand side.</li>
                                <li>lower lowm middle lane</li>
                                <li>Nasa road, Right hand side</li>
                            </ol>

                            <h3>Blocked driveways</h3>

                            <p>
                                An individual cannot park or leave a motor vehicle on either private property or municipal property without the consent of the property owner or occupant. You should contact the Multiplex Enforcement unit to file a complaint regarding illegal parking.
                            </p>
                        </div>
                    </section>

                </main>
                <Footer />

            </div>
        )
    }
}

export default EnforcementPage;
