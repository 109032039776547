
import React from "react";
import SectionHeader from "../../shared/SectionHeader";




function ContactSection() {

  return (
    // < !-- ======= Contact Section ======= -->
    <section id="contact" className="contact">
      <div className="container">

        <SectionHeader
          title="Contact"
          description="We would like you as our customer or client to contact us incase of any inquires or issues that you would like to address to us. Below are our contacts"
        />

        {/* <div className="section-title">
          <h2 data-aos="fade-up">Contact</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div> */}

        <div className="row justify-content-center">

          <div className="col-xl-3 col-lg-4" data-aos="fade-up">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Our Address</h3>
              <p>
                Plot 25, <br />
                P.O. Box 3874 Nakasero Rd
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="info-box mb-4">
              <i className="bx bxs-envelope"></i>
              <h3>Email Us</h3>
              <p>
                admin@Multiplexug.com <br />
                multiplexug@gmail.com
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-phone-call"></i>
              <h3>Call Us</h3>
              <p>
                +256 312 360100 <br />
                +256 393 202703
              </p>
            </div>
          </div>

        </div>

        <div className="row justify-content-center mb-5" data-aos="fade-up" data-aos-delay="300">
          <div className="col-xl-9 col-lg-12 mt-4">
            <form   className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

        <br />
        <br />

        <SectionHeader
          title="Location"
          // margin="mt-5"
          description="This is our location on the map... You are free to come to our offices for any inquiries or issues at hand to be addressed."
        />

        <div className="row justify-content-center mb-5" data-aos="fade-up" data-aos-delay="400">
          <div className="col-xl-9 col-lg-12">
            <iframe
              title="map"
              frameborder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7546074331563!2d32.57508161481122!3d0.32379889976376514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb77562b9095%3A0xc2d413e0625828d6!2sMultiplex%20Limited!5e0!3m2!1sen!2sug!4v1666103503739!5m2!1sen!2sug"
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >

            </iframe>
          </div>
        </div>

        <br />
        <br />

        <SectionHeader
          title="Branches"
          // margin="mt-5"
          description="These are our stationed branches where you can make payment or even inquire about certain information you might need to know from Multiplex Street parking..."
        />

        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="500">

          <div className="col-xl-3 col-lg-4" data-aos="fade-up">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "A"</h3>
              <p>
                at Master plaza, 1st Floor, Room F02, Next to Equatorial Building, Ben Kiwanuka.
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "B"</h3>
              <p>
                at Jumbo Plaza, Ground floor, shop No. 1
                along Parliament avenue
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "C"</h3>
              <p>
                at Fortune Paza, 1st floor, Shop No. FF11
                Wilson Street around Arua Park
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "D"</h3>
              <p>
                at Energy Centre Building, Ground Floor,
                Room D70 along Market Street
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "E"</h3>
              <p>
                at Securex (SAL Building), Ground floor, Room U031 along
                Nkurumah Road
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Branch "P"</h3>
              <p>
                General Post Office Building, Ground floor, Counter No.7 along Kampala road
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box mb-4">
              <i className="bx bxs-map"></i>
              <h3>Main Branch</h3>
              <p>
                Head Office Nakasero Road Plot 25
                <br/>
                <br/>
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>

    // {/* <!--End Contact Section-- > */}


  );

}

export default ContactSection;
