import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
import CareersSection from "../../Components/CareersSection/CareersSection";
import ClientsSection from "../../Components/ClientsSection/ClientsSection";



class CareersPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Careers"
                        back="Home"
                        backHref="/"
                    />



                    <CareersSection />

                    <ClientsSection />

                </main>
                <Footer />

            </div>
        )
    }
}

export default CareersPage;
