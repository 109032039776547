import React from 'react';
// import Loader from 'react-loader-spinner';

class ActivityLoader extends React.Component {


  render() {

    // const types = ['Circles', 'Audio', 'Ball-Triangle', 'Bars', 'Grid', 'Hearts', 'Oval', 'Puff', 'Rings', 'TailSpin', 'ThreeDots']
    // const type = this.props.type || 0
    // const height = this.props.height || 50
    // const width = this.props.width || 80
    // const color = this.props.color || "#e86f35"
    // const customClass = this.props.customClass || "col-xl-12 col-lg-12 col-12 text-center mt-5 mb-5"

    return (
      <>
        {/* <span className={customClass}>
          <Loader
            type={types[type]}
            color={color}
            height={height}
            width={width}
          />
        </span> */}

        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </>
    );


  }
}

export default ActivityLoader