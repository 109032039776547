import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CountUp from 'react-countup';

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
import PricingSection from "../../Components/PricingSection/PricingSection";

import construct1 from "../../assets/img/image.png";
import Slider1 from "../../Components/HeaderSection/Slide1";
import ValuesSection from "../../Components/ValuesSection/ValuesSection";
import SectionHeader from "../../shared/SectionHeader";


import value1 from "../../assets/img/image.png";
import value2 from "../../assets/img/construct4.jpg";

import concrete1 from "../../assets/img/concrete1.jpeg"
import concrete2 from "../../assets/img/concrete2.jpg"
import CounterSection from "../../Components/AboutSection/CounterSection";



const ConstructionPage = () => {

    return (
        <div>
            <FullTopNav />
            <Slider1 />

            <main id="main">

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <SectionHeader
                            title="Services"
                            description="Multiplex Construction endeavours to give its clients, customers and partners the best service it can avail and here are a few of the services Multiplex offers."
                        />

                        <div className="row gy-4">

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <i className='bx bxs-briefcase-alt-2'></i>
                                    </div>
                                    <h3>Construction Services</h3>
                                    <p>From roads to building to estblishements, multiplex construction is here to provide the best construction services for any all the projects that you have out there at affordable, diversified and flexible prices... </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className='bx bxs-category-alt'></i>
                                    </div>
                                    <h3>Building Materials</h3>
                                    <p>Creating a variety of building materials ranging from concrete products to metalic building materials, Multiplex construction makes and supplies to its clients in need of them. Offering all these at a reasonable and affordable price...</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bx bxs-buildings"></i>
                                    </div>
                                    <h3>Stone Production</h3>
                                    <p>Multiplex construction also does stone production which involves extracting natural stone from quarries and processing it into usable products for construction and decorative purposes...  </p>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>

                <section id="values" className="values section-bg">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                                <div className="card" style={{ backgroundImage: `url(${value1})` }}>
                                    <div className="card-body">
                                        <h5 className="card-title"><Link to="">Our Mission</Link></h5>
                                        <p className="card-text">To deliver exceptional construction services that exceed our clients' expectations through innovative solutions, unparalleled craftsmanship, and unwavering commitment to quality and safety. We strive to build lasting relationships with our clients, partners, and communities by consistently delivering projects on time, within budget, and to the highest standards of excellence.</p>

                                        <div className="read-more">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
                                <div className="card" style={{ backgroundImage: `url(${value2})` }}>
                                    <div className="card-body">
                                        <h5 className="card-title"><Link to="">Our Plan</Link></h5>
                                        <p className="card-text">We aim to achieve growth by leveraging advanced technologies, fostering skilled workforce development, and maintaining rigorous safety standards. We prioritize client satisfaction through transparent communication, efficient project management, and sustainable building practices. Our focus is on delivering high-quality construction projects on time and within budget, fostering long-term client relationships.</p>

                                        <div className="read-more">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">

                        <SectionHeader
                            title="Products"
                            description="Multiplex Construction also has some products it creates for its clients and offers them at very flexible affordable prices."
                        />

                        <ul className="products nav nav-tabs row mb-4 g-2 d-flex">

                            <li className="nav-item col-3">
                                <Link className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Concrete Products</h4>
                                </Link>
                            </li>

                            <li className="nav-item col-3">
                                <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Stone Products</h4>
                                </Link>
                            </li>

                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-1">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
                                        data-aos="fade-up" data-aos-delay="100">
                                        <h3>Concrete Products we provide</h3>
                                        <p className="fst-italic">
                                            These encompass a wide range of precast and poured items made from a mixture of cement, aggregates, and water. We make sure that our products are renowned for their durability, strength, and versatility, making them essential in construction and landscaping. ours products include items such: 
                                        </p>
                                        <ul>
                                            <li>Concrete blocks.</li>
                                            <li>Concrete pipe cullets.</li>
                                            <li>Concrete pavers.</li>
                                            <li>Concrete capstones.</li>
                                            <li>Concrete slabs.</li>
                                            <li>Concrete paving slabs.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                        <img src={concrete2} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-2">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Stone Products We Provide</h3>
                                        <p className="fst-italic">
                                            Our stone products are natural and also engineered materials used in construction, landscaping, and decorative applications. We offer them for their beauty, durability, and timeless appeal, and our products are often chosen for their unique textures, colors, and patterns.
                                        </p>
                                        <ul>
                                            <li>Slate Roofing.</li>
                                            <li>Sandstone Cladding.</li>
                                            <li>Limestone Pavers.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src={concrete1} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                {/* <section id="stats-counter" className="stats-counter">
                    <div className="container">

                        <SectionHeader
                            title="Our Stats"
                            description="Here are a few of our hardwork representation, our progress statistics that show how we have performed as Multiplex Construction."
                        />

                        <div className="row gy-4 w-75 mx-auto">

                            <div className="col-lg-4 col-md-4">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bx bxs-book-alt color-orange flex-shrink-0"></i>
                                    <div>
                                        <CountUp
                                            start={0}
                                            delay={1}
                                            end="4"
                                            duration={3}
                                            separator=","
                                        >
                                            <span className="purecounter" data-plugin="counterup">4</span>
                                        </CountUp>
                                        <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Projects</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bx bx-headphone color-green flex-shrink-0"></i>
                                    <div>
                                        <CountUp
                                            start={0}
                                            delay={1}
                                            end="21"
                                            duration={3}
                                            separator=","
                                        >
                                            <span className="purecounter" data-plugin="counterup">21</span>
                                        </CountUp>
                                        <p>Years Of Support</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bx bxs-user color-pink flex-shrink-0"></i>
                                    <div>
                                        <CountUp
                                            start={0}
                                            delay={1}
                                            end="475"
                                            duration={3}
                                            separator=","
                                        >
                                            <span className="purecounter" data-plugin="counterup">475</span>
                                        </CountUp>
                                        <p>Hard Workers</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section> */}

                {/* <section id="services-cards" className="services-cards">
                    <div className="container" data-aos="fade-up">

                        <div className="row gy-4">

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <h3>Quasi eaque omnis</h3>
                                <p>Eius non minus autem soluta ut ui labore omnis quisquam corrupti autem odit voluptas quos commodi magnam occaecati.</p>
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>Ullamco laboris nisi ut aliquip</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Duis aute irure dolor in reprehenderit</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Ullamco laboris nisi ut aliquip ex ea</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <h3>Et nemo dolores consectetur</h3>
                                <p>Ducimus ea quam et occaecati est. Temporibus in soluta labore voluptates aut. Et sit soluta non repellat sed quia dire plovers tradoria</p>

                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>Enim temporibus maiores eligendi</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Ut maxime ut quibusdam quam qui</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Officiis aspernatur in officiis</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                                <h3>Staque laboriosam modi</h3>
                                <p>Velit eos error et dolor omnis voluptates nobis tenetur sed enim nihil vero qui suscipit ipsum at magni. Ipsa architecto consequatur aliquam</p>
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>Quis voluptates laboriosam numquam</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Treva libero sunt quis veniam ut</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Debitis eos est est corrupti</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                                <h3>Dignissimos suscipit iste</h3>
                                <p>Molestiae occaecati assumenda quia saepe nobis recusandae at dicta ducimus sequi numquam commodi est in consequatur ea magnam quia itaque</p>
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>Veritatis qui reprehenderit quis</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Accusantium vel numquam sunt minus</span></li>
                                    <li><i className="bi bi-check2"></i> <span>Voluptatem pariatur est sationem</span></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </section> */}

                <section id="contact" className="contact">
                    <div className="container">

                        <SectionHeader
                            title="Contact"
                            description="We would like you as our customer or client to contact us incase of any inquires or issues that you would like to address to us. Below are our contacts"
                        />

                        <div className="row justify-content-center">

                            <div className="col-xl-3 col-lg-4" data-aos="fade-up">
                                <div className="info-box mb-4">
                                    <i className="bx bxs-map"></i>
                                    <h3>Our Address</h3>
                                    <p>
                                        Plot 25, <br />
                                        P.O. Box 3874 Nakasero Rd
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="info-box mb-4">
                                    <i className="bx bxs-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>
                                        admin@Multiplexug.com <br />
                                        multiplexug@gmail.com
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="info-box mb-4">
                                    <i className="bx bxs-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>
                                        +256 312 360100 <br />
                                        +256 393 202703
                                    </p>
                                </div>
                            </div>

                        </div>

                        

                        <br />
                        <br />

                        

                    </div>
                </section>

            </main>

            <Footer />

        </div>
    )

}

export default ConstructionPage;
