
import React from "react";
import { Link } from "react-router-dom";

import value1 from "../../assets/img/image.png";
import value2 from "../../assets/img/plexed.jpg";
// import value3 from "../../assets/img/values-3.jpg";
// import value4 from "../../assets/img/values-4.jpg";



function ValuesSection() {

    return (

        //   < !-- ======= Values Section ======= -->
        <section id="values" className="values section-bg">
            <div className="container">

                <div className="row">
                    <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="card" style={{ backgroundImage: `url(${value1})` }}>
                            <div className="card-body">
                                <h5 className="card-title"><Link to="">Our Mission</Link></h5>
                                <p className="card-text">To modernise and transform parking through investment in IT and Client sensitisation in order to; - Improve Revenue Collection Efficiency, - Improve Customer convenience, - Improve Service quality and - Eliminate Contravention of Parking Regulations</p>

                                <div className="read-more">
                                    {/* <Link to="#"><i className="bi bi-arrow-right"></i> Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
                        <div className="card" style={{ backgroundImage: `url(${value2})` }}>
                            <div className="card-body">
                                <h5 className="card-title"><Link to="">Our Plan</Link></h5>
                                <p className="card-text">Our plan is the same as our goal which is to provide safe, orderly, and fair parking for employees, students, and visitors to Kampala. We strive to make City parking on the Kampala streets as convenient as possible, while promoting safe movement of vehicles, providing for pedestrian safety.</p>

                                <div className="read-more">
                                    {/* <Link to="#"><i className="bi bi-arrow-right"></i> Read More</Link> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="card" style={{ backgroundImage: `url(${value3})` }}>
                            <div className="card-body">
                                <h5 className="card-title"><Link to="">Our Vision</Link></h5>
                                <p className="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores.</p>
                                <div className="read-more"><Link to="#"><i className="bi bi-arrow-right"></i> Read More</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="300">
                        <div className="card" style={{ backgroundImage: `url(${value4})` }}>
                            <div className="card-body">
                                <h5 className="card-title"><Link to="">Our Care</Link></h5>
                                <p className="card-text">Nostrum eum sed et autem dolorum perspiciatis. Magni porro quisquam laudantium voluptatem.</p>
                                <div className="read-more"><Link to="#"><i className="bi bi-arrow-right"></i> Read More</Link></div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        </section>
    );

    //  <!--End Values Section-- >

}

export default ValuesSection;
