import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
import ContactSection from "../../Components/ContactSection/ContactSection";



class ContactPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Contact"
                        back="Home"
                        backHref="/"
                    />

                    <ContactSection />

                </main>
                <Footer />

            </div>
        )
    }
}

export default ContactPage;
