import React from 'react';
import { Link } from 'react-router-dom';


function PrivacyContent() {

    const email = () => {
        window.open("mailto:multiplexug@gmail.com");
    }

    return (
        <div className="col-lg-8">
            <div className="portfolio-description">

                <p style={{ textAlign: "center" }}>Dated: 05 May, 2022</p>
                {/* <br /> */}
                {/* <br /> */}

                <p>Version: v1</p>

                <h2 id="1">BACKGROUND:</h2>

                <p>Multiplex understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website or uses our application and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the Data Protection and Privacy Act, 2019 and Regulations.</p>

                <p>Please read this Privacy Policy carefully and ensure that you understand it. By using our site and, or our application, you give the affirmative consent that you have read and accepted all the terms in this Privacy Policy. If you do not accept and agree with this Privacy Policy, you must stop using Our Site/application immediately.</p>

                <p>&nbsp;</p>

                <h2 id="2">Definitions and Interpretation</h2>

                <p>In this Policy the following terms shall have the following meanings:</p>

                <p><strong>&ldquo;Account&rdquo;</strong></p>
                <p>This means an account is required to access and/or use certain areas and features of Our Site or application;</p>

                <p><strong>&ldquo;Cookie&rdquo;</strong></p>
                <p>This means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in Part 14, below; and</p>

                <p><strong>&ldquo;Site&rdquo;</strong></p>
                <p>Means our website <Link to="#">www.plexparking.ug</Link> and/or the plex parking mobile application downloadable from the app store or play store or from our website.</p>

                <p>&nbsp;</p>

                <h2 id="3">Information About Us</h2>
                <p>Our Site and application are operated by Thrivetec Ug, a limited liability company registered in Uganda under company number, 80020000270706, and that operates in Uganda.&nbsp;</p>

                <p>The registered address for Uganda:</p>

                <div className="testimonial-item">

                    <p>
                        Block 210, <br />
                        Plot M799, spring road, <br />
                        P.O. Box 38709, Kampala, Uganda<br />
                        TIN Number: 1011089514
                    </p>

                    <p>
                        Data Protection Officer: Andrew Sekirime<br />
                        Email address: <Link to="mailto:asekirime@thrivetecug.com">asekirime@thrivetecug.com</Link><br />
                        Telephone number: +256704722190<br />
                        Postal Address: P.O. Box 38709 Kampala, Uganda
                    </p>

                    <br />
                </div>

                <p>We are regulated by Kampala Capital City Authority under trading license number 0006888456.</p>

                <p>&nbsp;</p>

                <h4 id="4">What Does This Policy Cover?</h4>
                <p>This Privacy Policy applies to your use of our Site and, or application. Please note that we have no control over how your data is collected, stored, or used by other websites whose links may be provided on our website and we advise you to check the privacy policies of any such websites before providing any data to them.</p>

                <p>&nbsp;</p>

                <h4 id="5">What is Personal Data?</h4>
                <p>Personal data is defined as any information relating to an identified or identifiable natural person (called a data subject) who can be directly or indirectly identified in particular by reference to an identifier such as name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>

                <p>&nbsp;</p>

                <h4 id="6">What Are My Rights?</h4>
                <p>As a data subject, you have the following rights, which we will always work to uphold:</p>

                <ul>
                    <li>
                        <p>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 14.</p>
                    </li>
                    <li>
                        <p>The right to access the personal data we hold about you. Part 12 will tell you how to do this.</p>
                    </li>
                    <li>
                        <p>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 14 to find out more.</p>
                    </li>
                    <li>
                        <p>The right to be forgotten, i.e., the right to ask us to delete or otherwise dispose of any of your personal data that we have. Please contact us using the details in Part 14 to find out more.</p>
                    </li>
                    <li>
                        <p>The right to restrict (i.e., prevent) the processing of your personal data.</p>
                    </li>
                    <li>
                        <p>The right to object to us using your personal data for a particular purpose(s).</p>
                    </li>
                    <li>
                        <p>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</p>
                    </li>
                </ul>

                <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 14.</p>

                <p>Further information about your rights can also be obtained from the National Data Protection Agency.</p>

                <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Data Protection Agency.</p>

                <p>&nbsp;</p>

                <h4 id="7">What Data Do We Collect?</h4>
                <p>Depending upon your use of our Site and, or application, we may collect some or all of the following personal and non-personal data including but not limited to;-</p>
                <ul>
                    <li>
                        <p>Name;</p>
                    </li>
                    <li>
                        <p>Email address;</p>
                    </li>
                    <li>
                        <p>Telephone number;</p>
                    </li>
                    <li>
                        <p>Payment information;</p>
                    </li>
                </ul>

                <br />

                <p>We use the information you give to;</p>
                <ul>
                    <li>
                        <p>Identify you</p>
                    </li>
                    <li>
                        <p>Locate you</p>
                    </li>
                    <li>
                        <p>Find parking for you</p>
                    </li>
                    <li>
                        <p>Link you to an approved payment system (plex parking &nbsp;wallet)</p>
                    </li>
                    <li>
                        <p>Improve the service such as saving common destinations</p>
                    </li>
                    <li>
                        <p>Connect services like &ldquo;Send&rdquo; and &ldquo;Share&rdquo; with your contacts</p>
                    </li>
                    <li>
                        <p>For product research development</p>
                    </li>
                    <li>
                        <p>To provide measurements and analytics</p>
                    </li>
                    <li>
                        <p>Communicate with you (share marketing communications on our products)</p>
                    </li>
                    <li>
                        <p>Generally, improve your user experience.</p>
                    </li>
                </ul>

                <p>&nbsp;</p>

                <h4 id="8">How Do You Use My Personal Data?</h4>
                <p>Further to clause 6, we use your data for the necessary performance of a contract with you and because you have consented to the use of your personal data, or because it is in our legitimate business interests to use it. Your personal data may also be used for the following purposes:</p>
                <ul>
                    <li>
                        <p>Providing and managing your Account;</p>
                    </li>
                    <li>
                        <p>Providing and managing your access to Our Site and, or application;</p>
                    </li>
                    <li>
                        <p>Personalizing and tailoring your experience on Our Site and, or application;</p>
                    </li>
                    <li>
                        <p>Supplying our services to you. Your personal details are required in order for us to enter into a contract with you.</p>
                    </li>


                    <li><p>Personalizing and tailoring our services for you.</p></li>
                    <li><p>Communicating with you. This may include responding to emails or calls from you.</p></li>
                    <li><p>Supplying you with information that you have the option to opt-out at any time</p></li>
                    <li><p>We may also use your personal data for marketing purposes, which may include contacting you by email, telephone, text message or any other means, with information, news, and offers on our services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the law, and you will always have the opportunity to opt-out.</p></li>
                    <li><p>Third Parties whose content appears on Our Site may use third-party Cookies, as detailed below in Part 13. Please refer to Part 13 for more information on controlling cookies. Please note that we do not control the activities of such third parties, nor the data that they collect and use themselves, and we advise you to check the privacy policies of any such third parties.</p></li>
                </ul>

                <p>&nbsp;</p>

                <h4 id="9">How Long Will You Keep My Personal Data?</h4>
                <p>We store data until it is no longer necessary to provide our services or until your account is deleted - whichever comes first. This is a case-by-case determination that depends on things like the nature of the data, why it is collected and processed, and relevant legal or operational retention needs in accordance with the law.</p>

                <p>&nbsp;</p>

                <h4 id="10">How and Where Do You Store or Transfer My Personal Data?</h4>
                <p>We will only store or transfer your personal data to countries that have adequate data protection standards/laws. We may in certain instances such as for analytics purposes or for the reasons in 6 and 7 above share your data with external third parties, that may be based out of your jurisdiction. In any such case, we shall ensure any such data is transferred securely and following best practices in your jurisdiction.</p>

                <p>Data at Plex parking is stored in renowned and secure servers. Data from the plex parking applications are stored in AWS Relational Database Service (RDS). Plex parking uses Postgres SQL as the database engine and all access to the database are confidential and password protected.</p>

                <p>&nbsp;</p>

                <h4 id="11">Do You Share My Personal Data?</h4>
                <p>We may share your personal data with processors and other companies in our group and this may include subsidiaries, a holding company, or any other entity that is a party to a merger and/or acquisition of an interest in Plex parking.&nbsp;</p>

                <p>We may sometimes contract with the following data processors to supply certain services. These may include payment processing, delivery, and marketing. In some cases, those parties may require access to some or all of the personal data that we hold.</p>

                <ul>
                    <li><p>WizRocket, trading as Clevertap, and based in the USA &ndash; the purpose for this is to process information about how customers use the Plex parking application and optimize the user experience. Please find Clevertap&rsquo;s privacy policy here.</p></li>
                    <li><p>Appsflyer, is a data processor that helps with data optimization and marketing purposes. Please find Appsflyer&rsquo;s privacy policy here.</p></li>
                    <li><p>Infobip, is a data processor that helps with security access to the Plex parking application. Please find Infobip&rsquo;s privacy policy here.</p></li>
                    <li><p>Trengo, is a data processor that helps to process customer communications through WhatsApp, Twitter, Facebook, and email. Please find Trengo&rsquo;s privacy policy here.&nbsp;</p></li>
                    <li><p>Messagebird, is a data processor that helps to manage WhatsApp communications. Please find Messagebird&rsquo;s privacy policy here.&nbsp;</p></li>
                    <li><p>Appruve, is a data processor that helps to manage our KYC process. Please find Appruve&rsquo;s privacy policy here.</p></li>
                    <li><p>XCally, is a data processor that helps to support our customer support quality monitoring processes. Please find XCally&rsquo;s privacy policy here.&nbsp;</p></li>
                    <li><p>SugaCRM, is a data processor that helps to support our customer support complaints and inquiries processes. Please find SugaCRM&rsquo;s privacy policy here.&nbsp;</p></li>
                </ul>

                <p>If any of your personal data is required by a processor, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party&rsquo;s obligations under the law. Without prejudice, you are advised to read the privacy policies of any processors we may contract.</p>

                <p>By using the Plex parking site/application, You (the User) give to Us (Plex parking) your consent to collect, use and process your data for the purposes and in the manner indicated herein and in the terms of use.</p>

                <p>&nbsp;</p>

                <h4 id="12">How Can I Control My Personal Data?</h4>
                <p>We provide you with the ability to access, rectify, port and erase your data. To learn more, visit the settings on the App.</p>

                <p>&nbsp;</p>

                <h4 id="13">How Can I Access My Personal Data?</h4>
                <p>If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a &ldquo;subject access request&rdquo;.</p>

                <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 14. We do not charge for a subject access request but if your request is &lsquo;manifestly unfounded or excessive (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</p>

                <p>We will respond to your subject access request within two weeks and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.</p>

                <p>&nbsp;</p>

                <h4 id="14">How Do You Use Cookies?</h4>
                <p>Our Site may place and access certain first-party Cookies on your computer or device. First-party Cookies are those placed directly by us and are used only by us. We use Cookies to facilitate and improve your experience of our Site and to provide and improve our services. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.</p>

                <p>By using our Site, you may also receive certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than us.</p>

                <p>Before Cookies are placed on your computer or device, you will be shown a pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of our Site may not function fully or as intended. You will be given the opportunity to allow only first-party Cookies and block third-party Cookies.</p>

                <p>Certain features of our Site depend on Cookies to function. These Cookies are shown in the table below. Your consent will not be sought to place these Cookies, but it is still important that you are aware of them. You may still block these Cookies by changing your internet browser&rsquo;s settings as detailed below, but please be aware that our Site may not work properly if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them.</p>

                <p>In addition to the controls that we provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third-party Cookies. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</p>

                <p>You can choose to delete Cookies on your computer or device at any time, however you may lose any information that enables you to access our Site more quickly and efficiently including, but not limited to, login and personalization settings.</p>

                <p>It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.</p>

                <p>&nbsp;</p>

                <h4 id="15">How Do I Contact You?</h4>
                <p>If you have any questions about this policy, you can contact us as described below.&nbsp;</p>

                <div className="testimonial-item">
                    <p>
                        Email: <Link onClick={email} to="#">multiplexug@gmail.com</Link>&nbsp;<br />
                        Telephone: 0200502050<br />
                        Twitter: @plexparking<br />
                        Instagram: @ Plexparking&nbsp;<br />
                        Facebook: Plexparking
                    </p>

                    <br />
                </div>

                <h3 id="16">Changes to this Privacy Policy</h3>
                <p>We may change this Privacy Policy from time to time and in some instances without notice to you. This may be necessary, for example, if the law changes, or we change our business in a way that affects personal data protection.</p>

                <p>&nbsp;</p>

                <p>Any changes will be immediately posted on our Site and, or application and you will be deemed to have accepted the terms of the Privacy Policy on your first use of our Site and, or application following the alterations. We recommend that you check this page regularly to keep up-to-date.</p>

                <p>By your use of our Site and, or application, you, by affirmative action, consent to these terms. Please do not use our Site and, or application or services if you do not consent to these terms.&nbsp;</p>

            </div>
        </div>
    )
}

export default PrivacyContent;
