import dictionary from "./dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');

const apiHost = dictionary.apiHost

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password,
            "app_type": ""
        }
        try {
            let response = await fetch(apiHost + 'user/login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listUsers(page) {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'user/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listBranches(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'branch/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async createBranch(branch_name, lat, lng) {

        let data = {
            "branch_name": branch_name,
            "lat": lat,
            "lng": lng
        }
        try {
            let response = await fetch(apiHost + 'branch.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async updateBranch(branch_id, branch_name, lat, lng) {

        let data = {
            "branch_name": branch_name,
            "lat": lat,
            "lng": lng,
            "branch_id": branch_id
        }
        try {
            let response = await fetch(apiHost + 'branch.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async deleteBranch(branch_id, user_id) {

        let data = {
            "branch_id": branch_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'branch.delete',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listStreets(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'street/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async createStreet(street_name, branch_id) {

        let data = {
            "street_name": street_name,
            "branch_id": branch_id

        }
        try {
            let response = await fetch(apiHost + 'street.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async updateStreet(street_id, street_name, branch_id) {

        let data = {
            "street_name": street_name,
            "street_id": street_id,
            "branch_id": branch_id
        }
        try {
            let response = await fetch(apiHost + 'street.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listLocations() {

        try {
            let response = await fetch(apiHost + 'location/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async createLocation(location_name, street_id, description, lat, lng, slots, is_nonparking_zone) {

        let data = {
            "location_name": location_name,
            "street_id": street_id,
            "description": description,
            "lat": lat,
            "lng": lng,
            "slots": slots,
            "is_nonparking_zone": is_nonparking_zone

        }
        try {
            let response = await fetch(apiHost + 'location.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async updateLocation(location_id, location_name, street_id, description, lat, lng, totalSlots, is_nonparking_zone) {

        let data = {
            "location_name": location_name,
            "street_id": street_id,
            "description": description,
            "location_id": location_id,
            "lat": lat,
            "lng": lng,
            "slots": totalSlots,
            "is_nonparking_zone": is_nonparking_zone
        }

        try {
            let response = await fetch(apiHost + 'location.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listDevices() {

        try {
            let response = await fetch(apiHost + 'device/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async createDevice(device_number) {

        let data = {
            "device_number": device_number

        }
        try {
            let response = await fetch(apiHost + 'device.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/profile',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async stageSession(plate_number) {

        let data = {
            "plate_number": plate_number,
        }
        try {
            let response = await fetch(apiHost + 'session.stage',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listUserLocation(user_id) {
        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'operations/user/location',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async startSession(plate_number, conductor_id, location_id, user_id, slots) {

        let data = {
            "plate_number": plate_number,
            "conductor_id": conductor_id,
            "location_id": location_id,
            "user_id": user_id,
            "slots": slots
        }
        try {
            let response = await fetch(apiHost + 'session.start',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async endSession(session_id, user_id) {

        let data = {
            "user_id": user_id,
            "session_id": session_id
        }

        try {
            let response = await fetch(apiHost + 'session.end',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async searchVehicle(hint, user_id) {

        let data = {
            "hint": hint,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/search',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listRecentSessions() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'session/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async countActiveSessions() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'session/active/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listRecentInvoices() {

        let data = {
            "source": "Admin"
        }

        try {
            let response = await fetch(apiHost + 'invoice/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listRecentPayments() {


        try {
            let response = await fetch(apiHost + 'payment/list/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listVehicleOutstandingInvoices(plate_number, user_id) {

        let data = {
            "user_id": user_id,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'invoice/vehicle/list/outstanding',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getVehicleByPlate(plate_number) {

        let data = {
            // "user_id": user_id,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'vehicle/search/plate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getVehicleOutstandingBalance(plate_number) {

        let data = {
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'invoice/vehicle/balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async makeOfficePayment(plate_number, amount, depositor_name, depositor_contact) {

        let data = {
            //"user_id":user_id,
            "plate_number": plate_number,
            "amount": amount,
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact
        }

        try {
            let response = await fetch(apiHost + 'payment/office/new',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listSessions(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'session/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countMonthlyInvoices() {

        let data = {
            "source":"admin"
        }

        try {
            let response = await fetch(apiHost + 'invoice/count/monthly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getMonthlyParkingRevenue() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'invoice/session/revenue/monthly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getUserLocation(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'location/user',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },
    async listInvoices(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'invoice/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async getMonthlyWrongParkingRevenue() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'invoice/wrongparking/revenue/monthly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async createInvoice(invoice_type_id, vehicle_id) {

        let data = {
            "invoice_type_id": invoice_type_id,
            "vehicle_id": vehicle_id

        }
        try {
            let response = await fetch(apiHost + 'invoice/create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },

    async listInvoiceTypes() {

        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'invoice/types',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }


    },

    async listAllVehicles() {

        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'vehicle/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }


    },

    async listAllCobras() {

        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'user/list/cobras',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }


    },

    async listClampTools(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'clamping/tool/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json(data);

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },


    async createClampTool(clamp_number) {

        let data = {
            "clamp_number": clamp_number,

        }
        try {
            let response = await fetch(apiHost + 'clamping/tool/create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },


    async updateClampTool(clamp_tool_id, clamp_number) {

        let data = {
            "clamp_number": clamp_number,
            "clamp_tool_id": clamp_tool_id
        }
        try {
            let response = await fetch(apiHost + 'clamping/tool/update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async respondToClampRequest(clamped_by_id, transaction_id) {


        let data = {
            "clamped_by_id": clamped_by_id,
            "clamp_transaction_id": transaction_id,
        }


        try {
            let response = await fetch(apiHost + 'clamping/request/respond',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },


    async clampVehicle(clamped_by_id, clamp_tool_id, transaction_id, clamping_location_id) {

        let data = {
            "clamped_by_id": clamped_by_id,
            "clamp_tool_id": clamp_tool_id,
            "clamp_transaction_id": transaction_id,
            "clamping_location_id": clamping_location_id,
        }


        try {
            let response = await fetch(apiHost + 'vehicle.clamp',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }

    },


    async listClampTransactions(page) {
        let data = {
            "page": page
        }
 

        try {
            let response = await fetch(apiHost + 'clamping/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listConductors() {

        let data = {
            "source": "admin",
        }
        try {
            let response = await fetch(apiHost + 'user/list/conductors',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async listClampTransactionsByStatus(clamp_status_id, page) {
        let data = {
            "clamp_status_id": clamp_status_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'clamping/list/criteria.status',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async listClampStatuses() {
        // let data = {
        //     "clamp_status_id":clamp_status_id
        // }


        try {
            let response = await fetch(apiHost + 'clamping/statuses/list',
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async listWrongParkingTransactions(page) {

        let data = {
            // "user_id":user_id,
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'wrongparking/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async reportWrongParking(plate_number, reported_by_id, location_id, description) {

        let data = {
            "plate_number": plate_number,
            "reported_by_id": reported_by_id,
            "location_id": location_id,
            "description": description
        }
        try {
            let response = await fetch(apiHost + 'vehicle.report_wrongparking',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }

    },














    async createUser(
        firstName,
        lastName,
        userName,
        email,
        profilePhoto,
        phoneNumber,
        roleId,
        createdBy
    ) {
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "profile_photo": profilePhoto,
            "phone_number": phoneNumber,
            "role_id": roleId,
            "created_by": createdBy
        }
        try {
            let response = await fetch(apiHost + 'user.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },



    async updateUser(
        userId,
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        profilePhoto,
        roleId,
        updatedBy
    ) {

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "phone_number": phoneNumber,
            "profile_photo": profilePhoto,
            "role_id": roleId,
            "updated_by": updatedBy,
            "user_id": userId
        }
        try {
            let response = await fetch(apiHost + 'user.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async createRole(roleName, code) {

        let data = {
            "role_name": roleName,
            "code": code,
            // "permission_id": permissionId
        }
        try {
            let response = await fetch(apiHost + 'user/role.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateRole(role_id, role_name) {

        let data = {
            "role_name": role_name,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'user/role.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRoles(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/role/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async deleteRole(role_id, user_id) {

        let data = {
            "role_id": role_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/role.delete',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listPermissions(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'permission/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async createPermission(permission, departmentModuleId, description) {

        let data = {
            "permission": permission,
            "module_id": departmentModuleId,
            "description": description,
        }
        try {
            let response = await fetch(apiHost + 'permission.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listDescriptions(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'description/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listPaymentDueDefaulters(page) {
        let data = {
            // "user_id":user_id,
            "page": page
        }

        // let data={
        //     // "user_id":user_id,
        //     "page":page 
        //     },
        // }

        try {
            let response = await fetch(apiHost + 'paymentdue/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async searchUser(hint, user_id) {

        let data = {
            "hint": hint,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/search',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async respondToUnClampVehicle(unclamped_by_id, transaction_id) {


        let data = {
            "unclamped_by_id": unclamped_by_id,
            "clamp_transaction_id": transaction_id
        }

        try {
            let response = await fetch(apiHost + 'unclamping/request/respond',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }
    },

    async unclampVehicle(unclamped_by_id, transaction_id) {

        let data = {
            "unclamped_by_id": unclamped_by_id,
            "clamp_transaction_id": transaction_id
        }
        try {
            let response = await fetch(apiHost + 'vehicle.unclamp',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }

    },




    async listVehiclePaymentHistory(plate_number, user_id) {

        let data = {
            "plate_number": plate_number,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'payment/list/vehicle',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listVehicleInvoiceHistory(plate_number, user_id, page) {

        let data = {
            "plate_number": plate_number,
            "user_id": user_id,
            "page":page
        }

        try {
            let response = await fetch(apiHost + 'invoice/list/vehicle',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },





    async countBranches(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'branch.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countStreets(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'street.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countLocations(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'location.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countDevices(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'device.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countUsers(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countRoles(user_id)//POST
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/role.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getClampThresholdFeeRecords()//GET
    {

        try {
            let response = await fetch(apiHost + 'clamping/threshold/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createClampingThreshold(amount)//POST
    {

        let data = {
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'clamping/threshold.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deleteClampingThreshold(id)//POST
    {

        let data = {
            "threshold_id": id
        }

        try {
            let response = await fetch(apiHost + 'clamping/threshold.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getPublicHolidays()//GET
    {

        try {
            let response = await fetch(apiHost + 'holiday/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createPublicHoliday(event_name, event_date)//POST
    {

        let data = {
            "event_name": event_name,
            "event_date": event_date
        }

        try {
            let response = await fetch(apiHost + 'holiday.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listAllZulus() {

        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'user/list/zulus',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }
    },

    async listRecentUsers(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deletePublicHoliday(holiday_id)//POST
    {

        let data = {
            "holiday_id": holiday_id,
        }

        try {
            let response = await fetch(apiHost + 'holiday.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countClampTools()//GET
    {

        try {
            let response = await fetch(apiHost + 'clamping/tool.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getActiveClampThreshold()//GET
    {

        try {
            let response = await fetch(apiHost + 'clamping/threshold/active',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getTotalHolidays()//GET
    {

        try {
            let response = await fetch(apiHost + 'holiday.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getSurchargeRates()//GET
    {

        try {
            let response = await fetch(apiHost + 'invoice/surcharge/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createSurcharge(amount)//POST
    {

        let data = {
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'invoice/surcharge.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deleteSurcharge(id)//POST
    {

        let data = {
            "charge_id": id
        }

        try {
            let response = await fetch(apiHost + 'invoice/surcharge.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getActiveSurcharge()//GET
    {

        try {
            let response = await fetch(apiHost + 'invoice/surcharge/active',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },






    async getWrongParkingFees()//GET
    {

        try {
            let response = await fetch(apiHost + 'wrongparking/fee',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createWrongParkingFee(amount)//POST
    {

        let data = {
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'wrongparking/fee.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deleteWrongParkingFee(id)//POST
    {

        let data = {
            "fee_id": id
        }

        try {
            let response = await fetch(apiHost + 'wrongparking/fee.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getActiveWrongParkingFee()//GET
    {

        try {
            let response = await fetch(apiHost + 'wrongparking/fee/active',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getWeekDays()//GET
    {

        try {
            let response = await fetch(apiHost + 'system/weekdays',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async toggleWorkDay(day_id, status)//POST
    {

        let data = {
            "day_id": day_id,
            "status": status
        }

        try {
            let response = await fetch(apiHost + 'workday.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getWorkingHours()//GET
    {

        try {
            let response = await fetch(apiHost + 'system/workhours',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async changeWorkingHours(opening, closing)//POST
    {

        let data = {
            "opening_time": opening,
            "closing_time": closing
        }

        try {
            let response = await fetch(apiHost + 'system/workhours.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async countPermissions(user_id) {
        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'permission.count',
                {
                    method: 'POST',
                    headers: {

                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countClampedVehicles() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'clamping/vehicles/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    // let result= await response.json();
    //         return result;
    //     } catch (error) {
    //         let connError={'status':'conn', 'details':{'message':'Contacting server....', 'content':false}}
    //         return connError;
    //     }


    async listTowTransactions(page) {
        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'towing/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listTowTransactionsByStatus(tow_status_id, page) {
        let data = {
            "tow_status_id": tow_status_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'towing/list/criteria.status',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
  
            let result = await response.json();
  
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },
 

    async getUsersByRole(role_id) {
        let data = {
            "role_id": role_id,
        }
        try {
            let response = await fetch(apiHost + 'user/list/criteria.role',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async listTowingStations() {
        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'towing/station/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async towVehicle(tow_transaction_id, towed_by_id, tow_station_id) {

        let data = {
            'tow_transaction_id': tow_transaction_id,
            'towed_by_id': towed_by_id,
            'tow_station_id': tow_station_id
        }
        try {
            let response = await fetch(apiHost + 'vehicle.tow',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }
    },

    async pickUpVehicle(tow_transaction_id, picked_by, phone_number, recorded_by_id, upload_id_photo

    ) {

        let data = {
            'tow_transaction_id': tow_transaction_id,
            'picked_by': picked_by,
            'phone_number': phone_number,
            'recorded_by_id': recorded_by_id,
            'upload_id_photo': upload_id_photo

        }
        try {
            let response = await fetch(apiHost + 'towing/vehicle.pickup',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }
    },

    async listPickedUpVehicles() {
        try {
            let response = await fetch(apiHost + 'towing/list/criteria.picked',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }
    },










    async listRecentStickerSales()//GET
    {
        try {
            let response = await fetch(apiHost + 'sticker/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listStickerTypes()//GET
    {

        try {
            let response = await fetch(apiHost + 'sticker/types',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createStickerSale(vehicle_id, sticker_type_id)//POST
    {

        let data = {
            "vehicle_id": vehicle_id,
            "sticker_type_id": sticker_type_id
        }

        try {
            let response = await fetch(apiHost + 'sticker.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async updateClientInfo(client_id, full_name, phone_number, email)//POST
    {

        let data = {
            "client_id": client_id,
            "full_name": full_name,
            "email": email,
            "phone_number": phone_number
        }

        try {
            let response = await fetch(apiHost + 'vehicle/client.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getUserLocationMapping()//GET
    {

        try {
            let response = await fetch(apiHost + 'location/user_mapping',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async set_user_location(user_id, location_id)//POST
    {

        let data = {
            "user_id": user_id,
            "location_id": location_id
        }

        try {
            let response = await fetch(apiHost + 'location/user.set',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async remove_user_location(allocation_id)//POST
    {

        let data = {
            "allocation_id": allocation_id,
        }

        try {
            let response = await fetch(apiHost + 'location/user.unset',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getRoleLocationMapping(role_id)//POST
    {

        let data = {
            "role_id": role_id
        }

        try {
            let response = await fetch(apiHost + 'location/role_mapping',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getUserDeviceMapping()//GET
    {

        try {
            let response = await fetch(apiHost + 'device/user_distribution',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getRoleDeviceMapping(role_id)//POST
    {

        let data = {
            "role_id": role_id
        }

        try {
            let response = await fetch(apiHost + 'device/role_distribution',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async setUserDevice(user_id, device_id)//POST
    {

        let data = {
            "device_id": device_id,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'device.set_user',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async returnDevice(device_allocation_id)//POST
    {

        let data = {
            "device_allocation_id": device_allocation_id,
        }

        try {
            let response = await fetch(apiHost + 'device.unset_user',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listStickerSales(page)//POST
    {

        let data = {
            "page": page,
        }

        try {
            let response = await fetch(apiHost + 'sticker/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async filterReceiptSales(page, date_from, date_to, sticker_type_id, keyword)//POST
    {

        let data = {
            "page": page,
            "sticker_type_id": sticker_type_id,
            "keyword": keyword,
            "date_from": date_from,
            "date_to": date_to
        }

        try {
            let response = await fetch(apiHost + 'sticker/list/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listTicketSales(page)//POST
    {

        let data = {
            "page": page,
        }

        try {
            let response = await fetch(apiHost + 'ticket/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listRecentTicketSales()//GET
    {

        try {
            let response = await fetch(apiHost + 'ticket/list/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async sellParkingTicket(amount, plate_number)//POST
    {

        let data = {
            "amount": amount,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'ticket.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getTicketFloatBalance(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'ticket/float/balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async loadTicketFloat(user_id, amount)//POST
    {

        let data = {
            "user_id": user_id,
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'ticket/float.load',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getVehicleTicketBalance(vehicle_id)//POST
    {

        let data = {
            "vehicle_id": vehicle_id
        }

        try {
            let response = await fetch(apiHost + 'ticket/vehicle.balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getVehicleActiveSticker(vehicle_id)//POST
    {

        let data = {
            "vehicle_id": vehicle_id
        }

        try {
            let response = await fetch(apiHost + 'sticker/vehicle.active',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listRecentDeposits() {
        try {
            let response = await fetch(apiHost + 'payment/deposit/list/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getDepositReceipts(deposit_id)//POST
    {

        let data = {
            "deposit_id": deposit_id
        }

        try {
            let response = await fetch(apiHost + 'payment/deposit/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async analyseAnnualRevenueCollection()//GET
    {

        try {
            let response = await fetch(apiHost + 'analysis/revenue/annual',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    //NOTIFICATIONS

    async getUserNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async readNotification(notification_id)//POST
    {

        let data = {
            "notification_id": notification_id,
        }
        try {
            let response = await fetch(apiHost + 'notification.read',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async resetPassword(password) {

        let data = {
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'user/auth/password.reset',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listLocationCoordinates(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'location/list/criteria.coords',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listRecentRoles(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/role/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countTowedVehicles() {

        let data = {
            "source": "admin"
        }

        try {
            let response = await fetch(apiHost + 'towing/vehicle.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },








    async searchPermission(hint, user_id) {

        let data = {
            "hint": hint,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'permission/search',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },




    async assign_permission(roleId, permissionId)//POST
    {

        let data = {
            "role_id": roleId,
            "permission_id": permissionId
        }

        try {
            let response = await fetch(apiHost + 'permission/role.assign',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterUsers(
        createdFrom,
        createdTo,
        createdBy,
        keyword
    ) {

        let data = {
            "created_by": createdBy,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo
        }

        try {
            let response = await fetch(apiHost + 'user/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listAdmins()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/list/admins',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterSessions(
        createdFrom,
        createdTo,
        startedBy,
        keyword,
        page,
        status
    ) {

        let data = {
            "conductor_id": startedBy,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'session/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listResersavations(page)//POST
    {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'reservation/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listOrganisations(page)//POST
    {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'company/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listReservationPlans()//GET
    {

        try {
            let response = await fetch(apiHost + 'reservation/plan/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createReservation(organisationId, locationId, totalSlots, reservationPlanId, startDate)//POST
    {

        let data = {
            'organisation_id': organisationId,
            'location_id': locationId,
            'slots': totalSlots,
            'reservation_plan_id': reservationPlanId,
            'start_date': startDate
        }

        try {
            let response = await fetch(apiHost + 'reservation.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getLocation(locationId)//POST
    {

        let data = {
            'location_id': locationId
        }

        try {
            let response = await fetch(apiHost + 'location/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listAllOrganisations()//GET
    {


        try {
            let response = await fetch(apiHost + 'company/list/all',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listOrganisationType()//GET
    {

        try {
            let response = await fetch(apiHost + 'company/type/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createOrganisation(title, locationAddress, contact, emailAddress, organisationType)//POST
    {

        let data = {
            'title': title,
            'location_address': locationAddress,
            'contact': contact,
            'email_address': emailAddress,
            'organisation_type': organisationType
        }

        try {
            let response = await fetch(apiHost + 'company.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getAvailableParking()//GET
    {

        try {
            let response = await fetch(apiHost + 'slot/available.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countUnreadNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/unread.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },




    async getRecentNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getUreadNotifications(user_id, status)//POST
    {

        let data = {
            "user_id": user_id,
            "status": status
        }

        try {
            let response = await fetch(apiHost + 'notification/list/criteria/unread',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getNotificationConfigs()//GET
    {

        try {
            let response = await fetch(apiHost + 'notification/config',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async turnOffNotifications(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/app.off',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async turnOnNotifications(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/app.on',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async turnOnSms(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/sms.on',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async turnOffSms(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/sms.off',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async listAllocatedConductors()//GET
    {

        try {
            let response = await fetch(apiHost + 'location/user/conductors/unassigned',
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async listDepartments() {
        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'department/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }
    },


    async getPaymentRecords(page, payment_method, processed_by, created_from, created_to)//POST
    {

        let data = {
            "page": page,
            "payment_method": payment_method,
            "processed_by": processed_by,
            "created_from": created_from,
            "created_to": created_to
        }

        try {
            let response = await fetch(apiHost + 'payment/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),

                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }
    },


    async listDepartmentModules() {
        // let data={
        //     "user_id":user_id,
        // }
        try {
            let response = await fetch(apiHost + 'department/modules/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listPermissionsByModule(moduleId) {

        let data = {
            "module_id": moduleId,
        }
        try {
            let response = await fetch(apiHost + 'permission/list/criteria.module',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getVehicleStatement(plate_number, page)//POST
    {

        let data = {
            "page": page,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'finance/vehicle/statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getGeneralVehicleStatement(page)//POST
    {

        let data = {
            "page": page,
        }

        try {
            let response = await fetch(apiHost + 'finance/vehicles/statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async link_vehicle(plate_number, client_id)//POST
    {

        let data = {
            "client_id": client_id,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'vehicle.link',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async list_client_vehicle(client_id)//POST
    {

        let data = {
            "client_id": client_id
        }

        try {
            let response = await fetch(apiHost + 'client/vehicle/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async unlink_vehicle(plate_number)//POST
    {

        let data = {
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'vehicle.unlink',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listUsersByRole(user_id, role_id) {

        let data = {
            "user_id": user_id,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'user/list/criteria.role',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async changeClampingClosingHours(clamping_closing_time, user_id)//POST
    {

        let data = {
            "clamping_closing_time": clamping_closing_time,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'system/clamphours.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getTodaysNotifications(user_id, status)//POST
    {

        let data = {
            "user_id": user_id,
            status: status
        }

        try {
            let response = await fetch(apiHost + 'notification/list/today',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createTowingStation(station_name, description, lat, lng) {

        let data = {
            "station_name": station_name,
            "description": description,
            "lat": lat,
            "lng": lng
        }
        try {
            let response = await fetch(apiHost + 'towing/station.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },

    async updateTowingStation(tow_station_id, station_name, description, lat, lng) {

        let data = {
            "station_name": station_name,
            "tow_station_id": tow_station_id,
            "description": description,
            "lat": lat,
            "lng": lng
        }
        try {
            let response = await fetch(apiHost + 'towing/station.upadte',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listContraventions() {

        try {
            let response = await fetch(apiHost + 'contravention/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async reportAndClamp(plate_number, clamped_by_id, clamp_tool_id, clamping_location_id, clamped_for, description_id) {

        let data = {
            "plate_number": plate_number,
            "clamped_by_id": clamped_by_id,
            "clamp_tool_id": clamp_tool_id,
            "clamping_location_id": clamping_location_id,
            "clamped_for": clamped_for,
            "description_id": description_id
        }
        try {
            let response = await fetch(apiHost + 'vehicle.clamp.direct',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }

    },

    async listAbortReasons() {

        try {
            let response = await fetch(apiHost + 'clamping/request/abort/reasons',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listSingleAbortReason(reason_id) {
        let data = {
            "reason_id": reason_id
        }

        try {
            let response = await fetch(apiHost + 'clamp/request/abort/reason',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),

                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async abortClampedVehicle(clamp_transaction_id, location_id, reason_id) {


        let data = {
            "location_id": location_id,
            "reason_id": reason_id,
            "clamp_transaction_id": clamp_transaction_id
        }

        try {
            let response = await fetch(apiHost + 'clamp/request/abort',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },










    async add_vehicle_to_vip(plate_number, reason)//POST
    {

        let data = {
            "plate_number": plate_number,
            "reason": reason
        }

        try {
            let response = await fetch(apiHost + 'vip.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async remove_vehicle_from_vip(plate_number)//POST
    {

        let data = {
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'vip.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async addToWhiteList(plate_number, amount, balance_date, comment)//POST
    {

        let data = {
            "amount": amount,
            "balance_date": balance_date,
            "comment": comment,
            "plate_number": plate_number
        }

        try {
            let response = await fetch(apiHost + 'whitelist.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listWhiteList(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'whitelist/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterWhiteList(
        createdFrom,
        createdTo,
        startedBy,
        keyword,
        page,
        status
    ) {

        let data = {
            "conductor_id": startedBy,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'whitelist/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async verifyUserPassword(password)//POST
    {

        let data = {
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'user/auth/verify_password',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async cancelWhiteList(white_list_id, password)//POST
    {

        let data = {
            "password": password,
            "white_list_id": white_list_id
        }

        try {
            let response = await fetch(apiHost + 'whitelist.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async approveWhiteList(white_list_id, password)//POST
    {

        let data = {
            "password": password,
            "white_list_id": white_list_id
        }

        try {
            let response = await fetch(apiHost + 'whitelist.approve',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async processWhiteList(white_list_id, password)//POST
    {

        let data = {
            "password": password,
            "white_list_id": white_list_id
        }

        try {
            let response = await fetch(apiHost + 'whitelist.process',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async terminateWhiteList(white_list_id, password)//POST
    {

        let data = {
            "password": password,
            "white_list_id": white_list_id
        }

        try {
            let response = await fetch(apiHost + 'whitelist.terminate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countPendingWhiteList()//GET
    {

        try {
            let response = await fetch(apiHost + 'whitelist/pending.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async makeWhiteListPayment(white_list_id, depositor_name, depositor_contact) {

        let data = {
            "white_list_id": white_list_id,
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact
        }

        try {
            let response = await fetch(apiHost + 'payment/office/whitelist/new',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listVehiclesWithBalances(page) {

        let data = {
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'finance/vehicles/balance/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countPaymentModes() {

        try {
            let response = await fetch(apiHost + 'payment/method.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },

    async getAllPaymentModes() {

        try {
            let response = await fetch(apiHost + 'payment/method/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async addPaymentMode(mode_name, mode_code, manual, enabled)//POST
    {

        let data = {
            "mode_name": mode_name,
            "mode_code": mode_code,
            "manual": manual,
            "enabled": enabled
        }

        try {
            let response = await fetch(apiHost + 'payment/method.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async updatePaymentMode(mode_id, mode_name, mode_code, manual, enabled)//POST
    {

        let data = {
            "mode_name": mode_name,
            "mode_code": mode_code,
            "manual": manual,
            "enabled": enabled,
            "mode_id": mode_id
        }

        try {
            let response = await fetch(apiHost + 'payment/method.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getOfficePaymentModes()//GET
    {

        try {
            let response = await fetch(apiHost + 'payment/method/office/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },

    async getBankingClients()//GET
    {

        try {
            let response = await fetch(apiHost + 'system/bank/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },


    async depositVehicleCheque(
        depositor_name,
        depositor_contact,
        amount,
        cheque_number,
        cheque_date,
        bank_id,
        cheque_vehicles
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "cheque_number": cheque_number,
            "cheque_date": cheque_date,
            "bank_id": bank_id,
            "cheque_vehicles": cheque_vehicles
        }

        try {
            let response = await fetch(apiHost + 'payment/vehicle/cheque.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countPendingCheques()//GET
    {

        try {
            let response = await fetch(apiHost + 'payment/cheque/pending.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listChequeRecords(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'payment/cheque/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getChequeRecord(cheque_id) {

        let data = {
            "cheque_id": cheque_id,
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque/info',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getVehiclesForCheque(cheque_id) {

        let data = {
            "cheque_id": cheque_id,
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque/vehicle_list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async updateVehicleAmountsForCheque(vehicle_sheet) {

        let data = {
            "vehicle_sheet": vehicle_sheet,
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque/vehicle_amount.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async approveCheque(cheque_id, password) {

        let data = {
            "cheque_id": cheque_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque.approve',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async processCheque(cheque_id, password) {

        let data = {
            "cheque_id": cheque_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque.process',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async cancelCheque(cheque_id, password) {

        let data = {
            "cheque_id": cheque_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque.cancel',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listLocationZones(user_id, status) {

        let data = {
            "user_id": user_id,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'location/zone/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },








    async getChequePayment(cheque_id) {

        let data = {
            "cheque_id": cheque_id,
        }
        try {
            let response = await fetch(apiHost + 'payment/cheque/receipt',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },













    //RTJS
    async depositVehicleRTJS(
        depositor_name,
        depositor_contact,
        amount,
        source_description,
        transaction_date,
        bank_id,
        vehicle_list
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "source_description": source_description,
            "transaction_date": transaction_date,
            "bank_id": bank_id,
            "vehicle_list": vehicle_list
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async countPendingRTJS()//GET
    {

        try {
            let response = await fetch(apiHost + 'payment/rtjs/pending.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listRTJSRecords(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'payment/rtjs/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getRTJSRecord(rtjs_id) {

        let data = {
            "rtjs_id": rtjs_id,
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs/info',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getVehiclesForRTJS(rtjs_id) {

        let data = {
            "rtjs_id": rtjs_id,
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs/vehicle_list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async getRTJSPayment(rtjs_id) {

        let data = {
            "rtjs_id": rtjs_id,
        }
        try {
            let response = await fetch(apiHost + 'payment/rtjs/payment_info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async approveRTJS(rtjs_id, password) {

        let data = {
            "rtjs_id": rtjs_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs.approve',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async processRTJS(rtjs_id, password) {

        let data = {
            "rtjs_id": rtjs_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs.process',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async cancelRTJS(rtjs_id, password) {

        let data = {
            "rtjs_id": rtjs_id,
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs.cancel',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async updateVehicleAmountsForRTJS(vehicle_sheet) {

        let data = {
            "vehicle_sheet": vehicle_sheet,
        }

        try {
            let response = await fetch(apiHost + 'payment/rtjs/vehicle_amount.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    //EOF RTJS



    //VEHICLES

    async getVehicleRecords(page, vip_status) {

        let data = {
            "vip_status": vip_status,
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'vehicle/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },




    async getVehicleStatistics()//GET
    {

        try {
            let response = await fetch(apiHost + 'vehicle/statistics',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getVehicleTypes()//GET
    {

        try {
            let response = await fetch(apiHost + 'vehicle/type/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async changeVehicleType(plate_number, type_id)//POST
    {

        let data = {
            "plate_number": plate_number,
            "type_id": type_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle.type.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async createNewVehicle(plate_number, vehicle_type, full_name, phone_number, email)//POST
    {

        let data = {
            "plate_number": plate_number,
            "vehicle_type": vehicle_type,
            "full_name": full_name,
            "phone_number": phone_number,
            "email": email
        }

        try {
            let response = await fetch(apiHost + 'vehicle.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getParkingBalance()//GET
    {

        try {
            let response = await fetch(apiHost + 'finance/parking/balance',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    //EO VEHICLES


    // RESERVATION
    async getReservationBalance()//GET
    {

        try {
            let response = await fetch(apiHost + 'finance/reservation/balance',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getReservation(reservation_id)//POST
    {

        let data = {
            "reservation_id": reservation_id
        }

        try {
            let response = await fetch(apiHost + 'reservation/info',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async getReservationCompanyBalance(company_id)//POST
    {

        let data = {
            "company_id": company_id
        }

        try {
            let response = await fetch(apiHost + 'finance/reservation/company/balance',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async makeReservationCashPayment(reservation_id, amount, depositor_name, depositor_contact) {

        let data = {
            "reservation_id": reservation_id,
            "amount": amount,
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact
        }

        try {
            let response = await fetch(apiHost + 'payment/reservation/cash/new',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async getReservationPaymentRecords(page, payment_method)//POST
    {

        let data = {
            "page": page,
            "payment_method": payment_method
        }

        try {
            let response = await fetch(apiHost + 'payment/reservation/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),

                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }
    },



    async getBranchStreets(user_id) {

        let data = {
            "user_id": user_id,
        }


        try {
            let response = await fetch(apiHost + 'branch.street/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterTicketSales(
        page,
        createdFrom,
        createdTo,
        createdBy,
        keyword
    ) {

        let data = {
            "page": page,
            "vendor_id": createdBy,
            "keyword": keyword,
            "date_from": createdFrom,
            "date_to": createdTo
        }


        try {
            let response = await fetch(apiHost + 'ticket/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getAverageLocationParkingDuration(user_id) {

        let data = {
            "user_id": user_id,
        }


        try {
            let response = await fetch(apiHost + 'session/location/average_duration',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async getAverageStreetParkingDuration(user_id) {

        let data = {
            "user_id": user_id,
        }


        try {
            let response = await fetch(apiHost + 'session/street/average_duration',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listSessionsInLocation(user_id, location_id, from, to) {

        let data = {
            "user_id": user_id,
            "location_id": location_id,
            "from": from,
            "to": to
        }


        try {
            let response = await fetch(apiHost + 'session/location/interval',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    //EO VENDOR














    async getSpaceAvailability() {



        try {
            let response = await fetch(apiHost + 'slot/available_parking',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },


    async getStreetSpaceAvailability(street_id) {


        let data = {
            "street_id": street_id,
        }


        try {
            let response = await fetch(apiHost + 'slot/availale_parking/street',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },

    async getStreet(street_id) {


        let data = {
            "street_id": street_id,
        }


        try {
            let response = await fetch(apiHost + 'street/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },



    async listRecentStreetSessions(street_id) {

        let data = {
            "street_id": street_id
        }

        try {
            let response = await fetch(apiHost + 'session/list/recent/street',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async listStreetLocation(street_id) {

        let data = {
            "street_id": street_id
        }

        try {
            let response = await fetch(apiHost + 'street.location/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getWeeklyStreetOccupancyData(street_id) {

        let data = {
            "street_id": street_id
        }

        try {
            let response = await fetch(apiHost + 'session/street/occupancy/weekly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },




    async getCashierDailyCashTotal(cashier_id) {

        let data = {
            "cashier_id": cashier_id
        }

        try {
            let response = await fetch(apiHost + 'finance/cashier/daily/cash',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getCashierDailyBankTotal(cashier_id) {

        let data = {
            "cashier_id": cashier_id
        }

        try {
            let response = await fetch(apiHost + 'finance/cashier/daily/bank',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async groupCashierDailyInvoiceTypes(cashier_id) {

        let data = {
            "cashier_id": cashier_id
        }

        try {
            let response = await fetch(apiHost + 'finance/cashier/daily/invoices/groups',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createVehicleListing(title, description) {

        let data = {
            "title": title,
            "description": description
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async populateVehicleListing(list_id, vehicle_id) {

        let data = {
            "list_id": list_id,
            "vehicle_id": vehicle_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing.add',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getUserVehicleListing(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing/user',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getVehicleListingInfo(list_id) {

        let data = {
            "list_id": list_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getUserRecentVehicleListing(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing/user/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async removeVehicleListingItem(item_id) {

        let data = {
            "item_id": item_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing.remove',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async importVehicleListing(list_id, list_file) {

        let data = {
            "list_file": list_file,
            "list_id": list_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async extractPaymentVehicleListing(list_id) {

        let data = {
            "list_id": list_id
        }

        try {
            let response = await fetch(apiHost + 'vehicle/listing/payment/extract',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async countStickerTypes() {

        try {
            let response = await fetch(apiHost + 'sticker/types.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },



    async createVehicleType(type_name, number_of_slots)//POST
    {

        let data = {
            "type_name": type_name,
            "number_of_slots": number_of_slots
        }

        try {
            let response = await fetch(apiHost + 'vehicle/type.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countVehicleTypes() {

        try {
            let response = await fetch(apiHost + 'vehicle/types.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },


    async updateVehicleType(id, type_name, number_of_slots)//POST
    {

        let data = {
            "type_id": id,
            "type_name": type_name,
            "number_of_slots": number_of_slots
        }

        try {
            let response = await fetch(apiHost + 'vehicle/types.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createStickerType(type_name, price, vehicle_type, duration)//POST
    {

        let data = {
            "type_name": type_name,
            "price": price,
            "vehicle_type": vehicle_type,
            "duration_in_days": duration
        }

        try {
            let response = await fetch(apiHost + 'sticker/types.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async updateStickerType(id, type_name, price, vehicle_type, duration)//POST
    {

        let data = {
            "type_id": id,
            "type_name": type_name,
            "price": price,
            "vehicle_type": vehicle_type,
            "duration_in_days": duration
        }

        try {
            let response = await fetch(apiHost + 'sticker/types.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async activateStickerType(id)//POST
    {

        let data = {
            "type_id": id
        }

        try {
            let response = await fetch(apiHost + 'sticker/types/activate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async deactivateStickerType(id)//POST
    {

        let data = {
            "type_id": id
        }

        try {
            let response = await fetch(apiHost + 'sticker/types/deactivate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listUnUsedClampTools() {

        try {
            let response = await fetch(apiHost + 'clamping/tool/list/un_used',

                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listSIngleClmapTool() {

        try {
            let response = await fetch(apiHost + 'clamping/tool/list/un_used',

                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getSingleClampTool(clamp_tool_id) {
        let data = {
            "clamp_tool_id": clamp_tool_id
        }

        try {
            let response = await fetch(apiHost + 'clamping/tool/single',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async getBranch(branch_id) {

        let data = {
            // "user_id": user_id,
            "branch_id": branch_id


        }
        try {
            let response = await fetch(apiHost + 'branch/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countTowingStations()//GET
    {

        try {
            let response = await fetch(apiHost + 'towing/station/count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    //CLAMP TOOLS BY STATUS
    async listClampToolsByStatus(in_use) {

        let data = {
            "in_use": in_use
        }

        try {
            let response = await fetch(apiHost + 'clamping/tool/list/status',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    //CLAMP TOOLS BY STATUS
    async getClampToolStatistics() {

        try {
            let response = await fetch(apiHost + 'clamping/tool/statistics',
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async listVehicleOccupancy(page, street_id, created_from, created_to, location_id) {

        let data = {
            "page": page,
            "street_id": street_id,
            "created_from": created_from,
            "created_to": created_to,
            "location_id": location_id
        }

        console.log(data)
        try {
            let response = await fetch(apiHost + 'session/street/location/occupancy',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async requestPermission(request_permission) {

        let data = {
            "request_permission": request_permission,
        }
        try {
            let response = await fetch(apiHost + 'permission.request',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listAllRequestedPermissions(user_id) {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'permission/request.list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async depositStickerCheque(
        depositor_name,
        depositor_contact,
        amount,
        cheque_number,
        cheque_date,
        bank_id,
        cheque_vehicles
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "cheque_number": cheque_number,
            "cheque_date": cheque_date,
            "bank_id": bank_id,
            "cheque_vehicles": cheque_vehicles
        }

        try {
            let response = await fetch(apiHost + 'payment/sticker/cheque.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async filterLocations(
        createdFrom,
        createdTo,
        streetName,
        keyword,
        page,
        status
    ) {

        let data = {
            "street_name": streetName,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            "status": status
        }

        console.log(data)
        try {
            let response = await fetch(apiHost + 'streets/parking_demand/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterVehicles(
        createdFrom,
        createdTo,
        vipValue,
        keyword,
        page,
        // status
    ) {

        let data = {
            "vipValue": vipValue,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            // "status": status
        }

        try {
            let response = await fetch(apiHost + 'vehicle/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getRoleInfo(roleId)//POST
    {

        let data = {
            'role_id': roleId
        }

        try {
            let response = await fetch(apiHost + 'user/role/info',

                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countPermissionsPerRole(user_id, role_id)//POST
    {

        let data = {
            "user_id": user_id,
            "role_id": role_id,
        }

        try {
            let response = await fetch(apiHost + 'role.permission.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },





    async getVehiclesForStickerCheque(cheque_id) {

        let data = {
            "cheque_id": cheque_id,
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque/sticker/vehicle_list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async updateStickerTypesForCheque(vehicle_sheet) {

        let data = {
            "vehicle_sheet": vehicle_sheet,
        }

        try {
            let response = await fetch(apiHost + 'payment/cheque/sticker/sticker_type.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },



    async depositVehicleCardPayment(
        customer_name,
        phone,
        amount,
        email,
        card_vehicles
    )//POST
    {

        let data = {
            "customer_name": customer_name,
            "phone": phone,
            "amount": amount,
            "email": email,
            "card_vehicles": card_vehicles
        }

        console.log(data)

        try {
            let response = await fetch(apiHost + 'payment/card/request',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    

    async filterSpaceInventory(
        street_id,
        createdFrom,
        createdTo,
        location_id,
        // vipValue,
        // keyword,
        page
        // status
    ) {

        let data = {
            "street_id": street_id,
            "location_id": location_id,


            // "vipValue": vipValue,
            // "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            // "status": status
        }

        console.log(data)
        try {
            let response = await fetch(apiHost + 'location/filter/space_inventory',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async listCashiersAndSuperAdmins(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/list/cashiers_and_superadmin',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },



    async listCashiersAndSuperAdmins(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/list/cashiers_and_superadmin',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async analyseAnnualSessionCollection()//GET
    {

        try {
            let response = await fetch(apiHost + 'analysis/sessions/annual',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async locationParkingDemand(page) {
        const data = { "page": page }

        try {
            let response = await fetch(apiHost + 'location/list/space_inventory',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    // FILTER CLAMP TRANSACTIONS
    async filterClampTransactions(vehicle_id, initial_location_id, clamped_location_id, clamped_for, created_from, created_to, clamp_status_id, page) {
        let data = {
            "vehicle_id": vehicle_id,
            "initial_location_id": initial_location_id,
            "clamped_location_id": clamped_location_id,
            "clamped_for": clamped_for,
            "created_from": created_from,
            "created_to": created_to,
            "clamp_status_id": clamp_status_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'filter/clamp-transactions',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },



}


