import React from "react";

// import Footer from "../../shared/Footer";
// import BreadCrumbs from "../../shared/BreadCrumbs";
// import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import ContactSection from "../../Components/ContactSection/ContactSection";
// import Notfoundpage from "../../shared/404";
import NotFound from "../../shared/404";
// import ServicesDetail from "../../Components/ServicesSection/ServicesDetail";



class PlexPage extends React.Component {

    render() {

        return (
            <div>
                {/* <FullTopNav /> */}

                <main id="main">

                    {/* <BreadCrumbs
                        title="Services"
                        back="Home"
                        backHref="/"
                    /> */}

                    {/* <ServicesDetail /> */}

                    <section id="contact" className="contact">
                        <div className="container">
                            <NotFound />
                        </div>
                    </section>

                </main>
                {/* <Footer /> */}

            </div>
        )
    }
}

export default PlexPage;
