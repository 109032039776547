const dictionary = {
    // "_completeFields": "Complete all fields and try again",
    // "_enterUsername": "Enter username and password",
    // "_requireBranchName": "Branch Name is required",
    // "_requirePlateNumber": "Vehicle plate number is required",
    // "_requireLocation": "Select location and try again",
    // "_waitTime":3000,
    // "_toastTimeOut": 5000,
    // "_requireclampNumber": "Clamp number is required",

    // "_waitTime": 1000,

    // "_authorization": "Access Denied! You are unauthorized to access the app",
    // "_requireRoleName": "Role name or Code is empty, provide one please",
    // "_requirePermission": "One of the fields is empty, fill them please",

    // "_requireAmount":"Enter amount and try again",
    // "_sellSticker":"Sell Sticker",
    // "_sellTicket":"Sell Ticket",
    // "_resetPassword":"Please Reset your password",
    // "_enterMatchingPasswords":"These Passwords don't match",
    "_apiKey":"AIzaSyAAAEuXK1fKaK8kjR3bOOmUzbKf8iScw-0",
    // "apiHost":"https://parking.thrivetecdev.com/",
    // "apiHost":"http://127.0.0.1/PMIS-API/",
    //  "apiHost":"http://10.10.10.71/api/api/PMIS-API/",
    // "apiHost":"http://parkingapi.lan/",
    //  "apiHost":"http://localhost/api/PMIS-API/",
    //  "apiAssets":"https://parking.thrivetecdev.com/img/",
    // "_requiretowingStation":"Towing Station ! is required",
    // "apiAssetsRoot":"https://parking.thrivetecdev.com/assets/",
}

export default dictionary;
