import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function PrivacyLinks() {

    const [hideNav, setHideNav] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        console.log("Y=" + scrollPosition)
        if (scrollPosition > 300) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    return (
        <div className="col-lg-3">
            <div className={hideNav ? "portfolio-info" : "portfolio-info"}>
                <h3>Policy content</h3>
                <ul>
                    <li>
                        <strong>Category</strong> <br />
                        <ol>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 444)}><span>Background</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 755)}><span>Definitions and Interpretation</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 1220)}><span>Information about us</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 1784)}><span>What Does This Policy Cover?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 1975)}><span>What is Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 2158)}><span>What Are My Rights?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 2885)}><span>What Data Do We Collect?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 3662)}><span>How Do You Use My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 4348)}><span>How Long Will You Keep My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 4538)}><span>How and Where Do You Store or Transfer My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 4817)}><span>Do You Share My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 5777)}><span>How Can I Control My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 5919)}><span>How Can I Access My Personal Data?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 6333)}><span>How Do You Use Cookies?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 7196)}><span>How Do I Contact You?</span></Link></li>
                            <li><Link to="#" onClick={() => window.scrollTo(0, 7475)}><span>Changes to this Privacy Policy</span></Link></li>
                        </ol>
                    </li>
                    <br />
                    <li><strong>Client</strong> <span>Plex Uganda</span></li>
                    <br />
                    <li><strong>Issue date</strong> <span>05 May, 2022</span></li>
                    <br />
                    {/* <li><strong>Project URL</strong> <Link to="#">www.plexparking.ug</Link></li>
                    <li><Link to="#" className="btn-visit align-self-start">Visit Website</Link></li> */}
                </ul>
            </div>
        </div>
    )
}

export default PrivacyLinks;
