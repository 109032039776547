import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/App.css'

import LandingPage from './Pages/Home/LandingPage';
import AboutPage from './Pages/About/AboutPage';
import ParkingMap from './Pages/Map/ParkingMap';
import ContactPage from './Pages/Contact/ContactPage';
import PrivacyPolicy from './Pages/Policies/PrivacyPolicy';
import EnforcementPage from './Pages/Enforcement/EnforcementPage';
import PaymentsPage from './Pages/Payments/PaymentsPage';
import TermsandConditionsPage from './Pages/Terms/TermsAndConditions';
import ServicesPage from './Pages/Services/ServicesPage';
import PlexPage from './Pages/Plex/PlexPage';
import ThrivePage from './Pages/Thrivetec/ThrivePage';
import ClientAboutPage from './Pages/About/ClientAboutPage';
import ClientPrivacyPolicy from './Pages/Policies/ClientPrivacyPolicy';
import CareersPage from './Pages/Careers/CareersPage';
import BlogPage from './Pages/Blog/BlogPage';
import SingleBlogPage from './Pages/Blog/SingleBlogPage';
import ConstructionPage from './Pages/Construction/ConstructionPage';
// import ParkMap from './Pages/Map/ParkMap';



function App() {

  return (

    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/app-about" element={<ClientAboutPage />} />
        <Route path="/payments" element={<PaymentsPage />} />
        <Route path="/map" element={<ParkingMap />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/enforcement" element={<EnforcementPage />} />
        <Route path="/construction" element={<ConstructionPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/single-blog" element={<SingleBlogPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/app-privacy-policy" element={<ClientPrivacyPolicy />} />
        <Route path="/tos" element={<TermsandConditionsPage />} />

        <Route path="/plex" element={<PlexPage />} />
        <Route path="/thrivetec" element={<ThrivePage />} />
        
      </Routes>
    </Router>
    
  );
}

export default App;
