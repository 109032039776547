
import React from "react";
import Header from "../../shared/Header";
import TopBar from "../../shared/TopBar";



function FullTopNav() {

    return (

        <div>
            <TopBar />
            <Header />
        </div>

    );

}

export default FullTopNav;
