import React from "react";

// import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
// import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import TeamSection from "../../Components/TeamSection/TeamSection";
// import CounterSection from "../../Components/AboutSection/CounterSection";
import AboutSection from "../../Components/AboutSection/AboutSection";



class ClientAboutPage extends React.Component {

    render() {

        return (
            <div>
                {/* <FullTopNav /> */}

                <main id="main">

                    <BreadCrumbs
                        title="About"
                        // back="Home"
                        // backHref="/"
                    />

                    <AboutSection />
                    {/* <CounterSection /> */}
                    {/* <TeamSection /> */}

                </main>
                {/* <Footer /> */}

            </div>
        )
    }
}

export default ClientAboutPage;
