import React from "react";
// import AboutSection from "../../Components/AboutSection/AboutSection";
// import ClientsSection from "../../Components/ClientsSection/ClientsSection";
// import ContactSection from "../../Components/ContactSection/ContactSection";
import FAQSection from "../../Components/FAQSection.js/FAQSection";
import Footer from "../../shared/Footer";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
import Slider from "../../Components/HeaderSection/Slide";
// import SliderSection from "../Components/HeaderSection/SliderSection";
// import PortifolioSection from "../../Components/PortifolioSection/PortifolioSection";
import PricingSection from "../../Components/PricingSection/PricingSection";
// import ServicesSection from "../../Components/ServicesSection/ServicesSection";
// import TeamSection from "../../Components/TeamSection/TeamSection";
import TestimonialSection from "../../Components/TestimonialSection/TestimonialSection";
import ValuesSection from "../../Components/ValuesSection/ValuesSection";
import WhyUsSection from "../../Components/WhyUsSection/WhyUsSection";
import PlexSection from "../../Components/AboutSection/PlexSection";



class LandingPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />
                <Slider />

                <main id="main">
                    <WhyUsSection />
                    <PlexSection />
                    <PricingSection button />
                    {/* <ServicesSection /> */}
                    <ValuesSection />
                    <TestimonialSection />
                    {/* <PortifolioSection /> */}
                    {/* <TeamSection /> */}
                    <FAQSection />
                    {/* <ClientsSection /> */}
                    {/* <ContactSection /> */}
                </main>

                <Footer />
            </div>
        )
    }
}

export default LandingPage;
