import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import ContactSection from "../../Components/ContactSection/ContactSection";
// import Notfoundpage from "../../shared/404";
import NotFound from "../../shared/404";



class TermsandConditionsPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Multiplex Terms"
                        back="Home"
                        backHref="/"
                    />

                    <section id="contact" className="contact">
                        <div className="container">
                            <NotFound />
                        </div>
                    </section>

                </main>
                <Footer />

            </div>
        )
    }
}

export default TermsandConditionsPage;
