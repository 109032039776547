import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import testimonials1 from "../../assets/img/testimonials/Edward-Mulondo.jpg";
import testimonials2 from "../../assets/img/testimonials/alvaro.jpeg";
import testimonials3 from "../../assets/img/testimonials/pius.JPG";
// import testimonials4 from "../../assets/img/testimonials/testimonials-4.jpg";

// import testimonials5 from "../../assets/img/testimonials/testimonials-5.jpg";
import SectionHeader from "../../shared/SectionHeader";


const properties = {
    duration: 7000,
    transitionDuration: 700,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 2
};

const responsiveSettings = [

    // {
    //     breakpoint: 100,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1
    //     }
    // },
    // {
    //     breakpoint: 300,
    //     settings: {
    //         arrows: false,
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //     }
    // },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 700,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },
    
];

function TestimonialSection() {

    return (
        // <!-- ======= Testimonials Section ======= -->
        <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">

                <SectionHeader
                    title="Testimonials"
                    description="Multiplex Limited is nothing without its clients. Here are our clients responses to the services we have offered to the public thus far"
                />

                <div className="slides-2 swiper">
                    <div className="testimonal-slide-wrapper">
                        <Slide
                            easing="ease"
                            {...properties}
                            responsive={responsiveSettings}
                        >
                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials1} className="testimonial-img" alt="" />
                                        <h3>Edward Mulondo</h3>
                                        <h4>C.E.O &amp; Founder - Thrivetec Limited</h4>
                                        {/* <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div> */}
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            As time and time has passed, the parking service has greatly improved and I believe a lot of clients including governement have benefitted greatly. Thank you Multiplex Limited...
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials2} className="testimonial-img" alt="" />
                                        <h3>Tebajjwa Alvaro</h3>
                                        <h4>Brand Manager - Arudem Gaming</h4>
                                        {/* <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div> */}
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            My parking sport is always good to go thanks to Multiplex. I even don't have issues making payment.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials3} className="testimonial-img" alt="" />
                                        <h3>Pius Selukwaya</h3>
                                        <h4>Systems Admin - Sido press Limited</h4>
                                        {/* <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div> */}
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            When I parked in town, I was really welcomed so weel by the multiplex workers and everything went smootly right from parking to even makig payment. Good Job
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials1} className="testimonial-img" alt="" />
                                        <h3>Matt Brandon</h3>
                                        <h4>Freelancer</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit
                                            minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials2} className="testimonial-img" alt="" />
                                        <h3>John Larson</h3>
                                        <h4>Entrepreneur</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim
                                            culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum
                                            quid.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </Slide>
                    </div>
                </div>

            </div>
        </section>
    );




}

export default TestimonialSection;
