import React, { useEffect, useState } from 'react';

import bg from "../assets/img/hero-bg1.jpg";


function BreadCrumbs({ title, backHref, back }) {

    const [hideNav, setHideNav] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    return (

        <div className={hideNav ? "breadcrumbs d-flex align-items-center" : "scrolled-offset breadcrumbs d-flex align-items-center"} style={{ backgroundImage: `url(${bg})` }}>
            <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                <h2>{title}</h2>
                <ol>
                    <li><a href={backHref}>{back}</a></li>
                    <li>{title}</li>
                </ol>

            </div>
        </div>
    )
}

export default BreadCrumbs;
