
import React from "react";
import { Link } from "react-router-dom";



function TopBar() {

    return (

        <section id="topbar" className="d-flex align-items-center">
            <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <i className="bx bxs-envelope emailIcon" />
                        <Link className="emailLink" to="mailto:contact@example.com">multiplexug@gmail.com</Link>
                    </div>
                    <div className="d-flex align-items-center ms-4">
                        <i className="bx bxs-phone phoneIcon" />
                        <span>+256-312-360100</span>
                    </div>
                </div>

                <div className="cta d-none d-md-flex align-items-center">
                    <Link to="/contact" className="scrollto">Contact us</Link>
                </div>
            </div>
        </section>

    );

}

export default TopBar;
