
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Fade } from "react-slideshow-image";

import '../../assets/css/construct.css'

// import slide1 from "../../assets/img/hero-bg1.jpg";
// import slide2 from "../../assets/img/hero-bg2.jpg";
// import slide3 from "../../assets/img/hero-bg3.jpg";
// import slide4 from "../../assets/img/hero-bg4.png";
// import slide5 from "../../assets/img/hero-bg5.png";
// import slide6 from "../../assets/img/hero-bg6.png";

import construct1 from "../../assets/img/construct1.jpg";
import construct2 from "../../assets/img/construct2.jpg";
import construct3 from "../../assets/img/construct3.jpg";

import "react-slideshow-image/dist/styles.css";


const properties = {
    duration: 5000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false
};


function Slider1() {

    const [hideNav, setHideNav] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }

    return (
        <>

            <section id="hero" className={hideNav ? "hero" : "scrolled-offset hero"}>
                <div  className="carousel slide construct">
                    <Fade easing="ease" {...properties}>
                        <div className="carousel-item" style={{ backgroundImage: `url(${construct1})` }}>
                            <div className="info d-flex align-items-center">
                                <div className="container" data-aos="fade-in">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 text-center">
                                            <h1>The Best <span>Quality Construction </span></h1>
                                            <p>Providing you the best construction material and services that best fit your needs
                                                and your establishments.</p>
                                            {/* <a href="#get-started" className="btn-get-started">Get Started</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item" style={{ backgroundImage: `url(${construct2})` }}>
                            <div className="info d-flex align-items-center">
                                <div className="container" data-aos="fade-in">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 text-center">
                                            <h1>Strong <span>Concrete Services</span></h1>
                                            <p>Providing you the best construction material and services that best fit your needs 
                                                and your establishments.</p>
                                            {/* <a href="#get-started" className="btn-get-started">Get Started</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item" style={{ backgroundImage: `url(${construct3})` }}>
                            <div className="info d-flex align-items-center">
                                <div className="container" data-aos="fade-in">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 text-center">
                                            <h1>Perfection <span>In all Materials</span></h1>
                                            <p>Providing you the best construction material and services that best fit your needs
                                                and your establishments.</p>
                                            {/* <a href="#get-started" className="btn-get-started">Get Started</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    {/* <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </a>

                    <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </a> */}
                </div>
            </section>
        </>

    );

}

export default Slider1;
