import React, { Component } from 'react'
import SectionHeader from '../../shared/SectionHeader'
import NoContent from '../NoContent'

export default class CareersSection extends Component {
    render() {
        return (
            <section id="services" className="services">
                <div className="container" data-aos="fade-up">

                    <SectionHeader
                        title="Careers"
                        description="Multiplex Limited endeavours to give back to the community by offering employment opportunities to the community people."
                    />

                    <div className="row gy-4">
                        <section id="contact" className="contact">
                            <div className="container">
                                <NoContent 
                                    subTitle="We currently don't have any employment opportunities at the moment"
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        )
    }
}
