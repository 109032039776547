import React from "react";

import Footer from "../../shared/Footer";
import BreadCrumbs from "../../shared/BreadCrumbs";
import FullTopNav from "../../Components/HeaderSection/FullTopNav";
// import ContactSection from "../../Components/ContactSection/ContactSection";
// import Notfoundpage from "../../shared/404";
// import NotFound from "../../shared/404";
import PricingSection from "../../Components/PricingSection/PricingSection";
// import SectionHeader from "../../shared/SectionHeader";



class PaymentsPage extends React.Component {

    render() {

        return (
            <div>
                <FullTopNav />

                <main id="main">

                    <BreadCrumbs
                        title="Payments"
                        back="Home"
                        backHref="/"
                    />

                    <section id="contact" className="contact">
                        <div className="container">
                            <PricingSection />

                            {/* <NotFound /> */}

                            {/* <h2>PARKING FEES</h2> */}

                            {/* <br />
                            <br /> */}

                            {/* <SectionHeader
                                title="Parking Fees"
                                description="Below is our pricing criteria for parking locations, slots and our reservation areas around the streets of Kampala and any other Multiplex operated area."
                            /> */}

                            <div className="table-wrap" data-aos="fade-up">
                                <table className="table table-responsive">
                                    <thead className="thead-primary">
                                        <tr>
                                            <th>Parking</th>
                                            <th>Stickers</th>
                                            <th>Reservation</th>
                                            <th>PMIS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1. Parking charge Ugx. 1000 (One Thousand Shillings)
                                                per hour and this shall apply to the first two hours of
                                                uninterrupted parking in the same space.</td>
                                            <td>
                                                <ol>
                                                    <li>Ordinary Monthly Sticker (one space) - 105,000=</li>
                                                    <li>Three Months Sticker - 270,000=</li>
                                                    <li>Taxi - 84,000=</li>
                                                    <li>Two Weeks Sticker - 55,000=</li>
                                                    <li>Lorry Sticker (2 Spaces) - 210,000=</li>
                                                </ol>
                                            </td>
                                            <td>Reserved Parking per Space - 300,000=</td>
                                            <td>Advance payment Receipt
                                                (Special Hire) - 4,000=</td>
                                        </tr>
                                        <tr>
                                            <td>2.Ugx. 800 (Eight Hundred Shillings) for any time up
                                                to 30 (Thirty) minutes over and above the first two
                                                hours of uninterrupted parking mentioned above.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <ol>
                                                    <li>Advance payment Receipt
                                                        (Special Hire) - 4,000=</li>
                                                    <li>Reserved Parking per Space - 300,000=</li>
                                                    <li>Surcharge - 2,000=</li>
                                                    <li>Clamping fee Non Payment - 40,000=</li>
                                                    <li>Towing Charges - 75,000=</li>
                                                </ol>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                        <td></td>
                                        <td>Taxi - 84,000=</td>
                                        <td></td>
                                        <td>Clamping fee Non Payment - 40,000=</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Two Weeks Sticker - 55,000=</td>
                                        <td></td>
                                        <td>Towing Charges - 75,000=</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Lorry Sticker (2 Spaces) - 210,000=</td>
                                        <td></td>
                                        <td></td>
                                    </tr> */}
                                    </tbody>
                                </table>
                            </div>

                            <br />
                            <br />

                            {/* <p>
                                1. Parking charge Ugx. 1000 (One Thousand Shillings)
                                per hour and this shall apply to the first two hours of
                                uninterrupted parking in the same space.
                                <br />
                                <br />
                                2.Ugx. 800 (Eight Hundred Shillings) for any time up
                                to 30 (Thirty) minutes over and above the first two
                                hours of uninterrupted parking mentioned above.
                            </p>

                            <h3>STICKERS</h3>

                            <ol>
                                <li>Ordinary Monthly Sticker (one space) - 105,000=</li>
                                <li>Three Months Sticker - 270,000=</li>
                                <li>Taxi - 84,000=</li>
                                <li>Two Weeks Sticker - 55,000=</li>
                                <li>Lorry Sticker (2 Spaces) - 210,000=</li>
                            </ol>

                            <p>Other Charges</p>

                            <ol>
                                <li>Advance payment Receipt
                                    (Special Hire) - 4,000=</li>
                                <li>Reserved Parking per Space - 300,000=</li>
                                <li>Surcharge - 2,000=</li>
                                <li>Clamping fee Non Payment - 40,000=</li>
                                <li>Towing Charges - 75,000=</li>
                            </ol> */}
                        </div>
                    </section>

                </main>
                <Footer />

            </div>
        )
    }
}

export default PaymentsPage;
