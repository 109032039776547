
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/img/logo.svg";


function Footer() {

    const [activeRoot, setActiveRoot] = useState('');
    const [activeLocationPath] = useState(window.location.pathname);

    useEffect(() => {
        setPath();
    });

    const setPath = () => {
        const pathSections = activeLocationPath.split("/");
        setActiveRoot("/" + pathSections[1])
    }

    const twitter = () => {
        window.open("https://www.twitter.com/Plexparking");
    }

    const instagram = () => {
        window.open("https://www.instagram.com/plexparking");
    }

    const facebook = () => {
        window.open("https://www.facebook.com/profile.php?id=100080291472976/");
    }

    const linkedin = () => {
        window.open("https://www.linkedin.com/in/plex-parking-46a736237");
    }

    return (

        //   < !-- ======= Footer ======= -->
        <>
            <footer id="footer">

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <div className="logo">
                                    <Link to="/"><img src={logo} alt="" className="img-fluid" /></Link>
                                </div>
                                <p>
                                    Multiplex Limited is an indigenous private company incorporated in Uganda in 1984, with an initial vision of contributing to the process of reconstruction of the national infrastructure after decades of civil strife and economic decline.
                                </p>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/' && 'active'}`} to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link></li>
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/about' && 'active'}`} to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link></li> */}
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/services' && 'active'}`} to="/services" onClick={() => window.scrollTo(0, 0)}>Services</Link></li> */}
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/enforcement' && 'active'}`} to="/enforcement" onClick={() => window.scrollTo(0, 0)}>Enforcement</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/payments' && 'active'}`} to="/payments" onClick={() => window.scrollTo(0, 0)}>Payments</Link></li>
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/map' && 'active'}`} to="/map" onClick={() => window.scrollTo(0, 0)}>Parking-map</Link></li> */}
                                    
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/enforcement' && 'active'}`} to="/enforcement" onClick={() => window.scrollTo(0, 0)}>Enforcement</Link></li> */}
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/contact' && 'active'}`} to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact us</Link></li>

                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/tos' && 'active'}`} to="/tos" onClick={() => window.scrollTo(0, 0)}>Terms of service</Link></li> */}
                                    
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/construction' && 'active'}`} to="/construction" onClick={() => window.scrollTo(0, 0)}>Construction</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Info Links</h4>
                                <ul>
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/blog' && 'active'}`} to="/blog" onClick={() => window.scrollTo(0, 0)}>Blog</Link></li> */}
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/about' && 'active'}`} to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/services' && 'active'}`} to="/services" onClick={() => window.scrollTo(0, 0)}>Services</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/careers' && 'active'}`} to="/careers" onClick={() => window.scrollTo(0, 0)}>Careers</Link></li>
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/payments' && 'active'}`} to="/payments" onClick={() => window.scrollTo(0, 0)}>Payments</Link></li> */}
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/map' && 'active'}`} to="/map" onClick={() => window.scrollTo(0, 0)}>Parking-map</Link></li> */}
                                    
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/enforcement' && 'active'}`} to="/enforcement" onClick={() => window.scrollTo(0, 0)}>Enforcement</Link></li> */}
                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/contact' && 'active'}`} to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact us</Link></li> */}

                                    {/* <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/tos' && 'active'}`} to="/tos" onClick={() => window.scrollTo(0, 0)}>Terms of service</Link></li> */}
                                    
                                    <li><i className="bx bx-chevron-right"></i> <Link className={`scrollto ${activeRoot === '/privacy-policy' && 'active'}`} to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy policy</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Reach out to us</h4>
                                <p>
                                    Plot 25 <br />
                                    P.O. Box 3874 <br />Nakasero Rd, Kampala<br />
                                    Uganda <br /><br />
                                    <strong>Phone:</strong> +256 312 360100<br />
                                    <strong>Email:</strong> multiplexug@gmail.com<br />
                                </p>
                                {/* <p>Sign up today and get aquinted with our newsletter and stay up to date with Multiplex</p>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                </form> */}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container d-lg-flex py-4">

                    <div className="me-lg-auto text-center text-lg-start">
                        <div className="copyright">
                            &copy; Copyright <strong><span>Multiplex Limited</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                            Since 2017, Managers of Street Parking
                        </div>
                    </div>
                    <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
                        <Link onClick={twitter} to="#" className="twitter"><i className="bx bxl-twitter"></i></Link>
                        <Link to="#" onClick={facebook} className="facebook"><i className="bx bxl-facebook"></i></Link>
                        <Link to="#" onClick={instagram} className="instagram"><i className="bx bxl-instagram"></i></Link>
                        {/* <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link> */}
                        <Link to="#" onClick={linkedin} className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                    </div>
                </div>
            </footer>
            {/* // <!-- End Footer --> */}
        </>



    );

}

export default Footer;
