
import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../shared/SectionHeader";



function FAQSection() {

    return (

        //   < !-- ======= F.A.Q Section ======= -->
        <section id="faq" className="faq">
            <div className="container">

                <SectionHeader
                    title="F.A.Qs"
                    description="Here are a few questions that We as Multiplex Limited have encountered in this line of work and have gladly answered to the best of our ability."
                />

                <div className="faq-list">
                    <ul>
                        <li data-aos="fade-up">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Where do I get parking in Kampala? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-1" className="collapse show faq-list-desc" data-bs-parent=".faq-list">
                                <p>
                                    Multiplex Limited operates a number of locations in the city and If you head over to the map section, you will be able to see all the locations that are under Multiplex Limited Street Parking that you can access parking from right here in the city. As for the basic ones, a few of them are:-
                                    <br />
                                    <br />1. Kampala road,
                                    <br />2. Entebbe road,
                                    <br />3. Jinja road,
                                    <br />4. Industrial area
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">How do I pay when I have finished parking? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-2" className="collapse faq-list-desc" data-bs-parent=".faq-list">
                                <p>
                                    Our workers (conductors) operate in each location and are responsible for starting parking and ending it. You can pay to them through ticket. You can also go to the operating branches at their respective locations and make cash payment from there. Multiplex has introduce a new way of payment where everything is cashless, in otherwords, no handling cash directly for the field workers, that means payment is done virtually through payment modes. These modes include:-
                                    <br />
                                    <br />1. Mobile money [*217*44# | *185#],
                                    <br />2. Cash payment at the branch offices,
                                    <br />3. Credit card,
                                    <br />4. Tickets to be given to field workers
                                    <br />5. RTGS for commercial companies
                                    <br />6. Virtual Tickets on the plex app
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="400">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">How can I avoid being clamped for Non-Payment? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-5" className="collapse faq-list-desc" data-bs-parent=".faq-list">
                                <p>
                                    1. If you Park regularly and for long period, buy monthly
                                    Parking Stickers
                                    <br />2. If you Park and you have No Sticker, Pay promptly
                                    using Parking Tickets and get a receipt
                                    <br />3. In case you are unable to Pay at location, Pay within
                                    two working days either at our seven outlets in the
                                    City or using Mobile Money
                                    <br />4. In case you do not use your vehicle alone periodically
                                    check its Parking Status on your phone using MTN
                                    Mobile Money or Airtel Money Platform and Settle
                                    any outstanding balance in full to avoid inconvenience
                                    and costs.
                                    <br />5. Before you buy a vehicle that has been in use in
                                    Uganda please check its parking fee status before
                                    finalizing a Purchase Agreement.
                                    <br />6. In case your vehicle is clamped for Non Payment or
                                    Contravention, you should endeavour to pay the bill
                                    before 6:00pm to avoid vehicle being Towed.
                                    <br />7. When you leave an indebted vehicle in parking it is
                                    also advisable to check with Multiplex\Ltd for unpaid
                                    paring bills.
                                    <br />8. In case you do not have all your payment by 5:30 pm ,
                                    approach our Office on Plot 25 Nakasero Road and
                                    present your reasonable/honest proposal for
                                    consideration
                                    <br />9. If you find a car missing after 6:00pm check with our
                                    Head Office before reporting the case of lost vehicle
                                    to police. Please note that vehicles are towed at
                                    owner’s risk and cost.
                                    {/* </ol> */}
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="200">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Is my property safe during parking? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-3" className="collapse faq-list-desc" data-bs-parent=".faq-list">
                                <p>
                                    Multiplex Limited is trying to find ways of helping its clients safe guard their property but as of now, when you park, please be adviced to always go with your property, please dont leave it in the car so that you avoid losing the property.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="300">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">How do I check my bills? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-4" className="collapse faq-list-desc" data-bs-parent=".faq-list">
                                <p>
                                    Multiplex has introduced an application, Plex, your one app closer to multiplex. You can use this app to check your balances on your parking and even know when you are due. If you dont have a smart phone, don't worry, just dial *217*44# and get to know your balances using a dialed code which works on any network. There are two methods:-
                                    <br />
                                    <br />1. Dial [*217*44#] and follow the instructions,
                                    <br />2. Download the Plex app from the Play store or Apple store
                                </p>
                            </div>
                        </li>

                        {/* <li data-aos="fade-up" data-aos-delay="400">
                            <i className="bx bx-help-circle icon-help"></i> <Link to="#" data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">How do I avoid clamping and towing? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                            <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Make sure that you have fully paid any due payments and leave no balance uncovered. Avoid wrong parking when parking in the streets of kampala.
                                </p>
                            </div>
                        </li> */}





                    </ul>
                </div>

            </div>
        </section>
        // <!--End F.A.Q Section-- >


    );

}

export default FAQSection;
