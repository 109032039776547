import React, { useEffect, useState } from "react";
import CountUp from 'react-countup';
import SectionHeader from "../../shared/SectionHeader";


function CounterSection() {

    const [experience, setExperience] = useState(0)

    const birthday = new Date('2003-05-17');
    const currentday = new Date();

    useEffect(() => {
        setExperience(currentday.getFullYear() - birthday.getFullYear())
    }, [experience])

    return (
        <section id="stats-counter" className="stats-counter">
            <div className="container">

                <SectionHeader
                    title="Our Stats"
                    description="Here are a few of our hardwork representation, our progress statistics that show how we have performed as Multiplex Limited."
                />

{/* <div className="mx-auto"> */}
                <div className="row gy-4 w-60 mx-auto">

                    {/* <div className="col-lg-3 col-md-6">
                        <div className="stats-item d-flex align-items-center w-100 h-100">
                            <i className="bx bxs-smile color-blue flex-shrink-0"></i>
                            <div>
                                <CountUp
                                    start={0}
                                    delay={1}
                                    end="5"
                                    duration={3}
                                    separator=","
                                >
                                    <span className="purecounter" data-plugin="counterup">5</span>
                                </CountUp>
                                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
                                <p>Happy Clients</p>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-3 col-md-6">
                        <div className="stats-item d-flex align-items-center w-100 h-100">
                            <i className="bx bxs-book-alt color-orange flex-shrink-0"></i>
                            <div>
                                <CountUp
                                    start={0}
                                    delay={1}
                                    end="4"
                                    duration={3}
                                    separator=","
                                >
                                    <span className="purecounter" data-plugin="counterup">4</span>
                                </CountUp>
                                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                                <p>Projects</p>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-6 col-md-6">
                        <div className="stats-item d-flex align-items-center w-100 h-100">
                            <i className="bx bx-headphone color-green flex-shrink-0"></i>
                            <div>
                                <CountUp
                                    start={0}
                                    delay={1}
                                    end={experience}
                                    duration={3}
                                    separator=","
                                >
                                    <span className="purecounter" data-plugin="counterup">{experience}</span>
                                </CountUp>
                                {/* <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" className="purecounter"></span> */}
                                <p>Years Of Support</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="stats-item d-flex align-items-center w-100 h-100">
                            <i className="bx bxs-user color-pink flex-shrink-0"></i>
                            <div>
                                <CountUp
                                    start={0}
                                    delay={1}
                                    end="475"
                                    duration={3}
                                    separator=","
                                >
                                    <span className="purecounter" data-plugin="counterup">475</span>
                                </CountUp>
                                {/* <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span> */}
                                <p>Hard Workers</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* </div> */}

            </div>
        </section>
    )

}

export default CounterSection;
