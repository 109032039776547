import React from 'react';
// import nocontent from '../../assets/no-content.svg';
import nocontent from '../assets/img/no-content.svg';

class NoContent extends React.Component {


    render() {

        return (
            <div className="col-12">
                <div className="py-4 my-2 d-flex align-items-center justify-content-center flex-column text-center">
                    {/* <img
            src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"}
            alt="logo"
            width="100px"
            className="img-fluid mx-auto d-block"
          /> */}
                    <img
                        // src={this.props.image || process.env.PUBLIC_URL + "../../assets/images/no_data.svg"}
                        src={nocontent}
                        alt="logo"
                        width="200px"
                        className="img-fluid mx-auto d-block my-4"
                    />
                    <h4 className="notfound-color">{this.props.title || "Oops! Multiplex says:"}</h4>
                    <p className="text-muted">{this.props.subTitle || "There seems not be any available data here, sorry."}</p>
                </div>
            </div>

        );


    }
}

export default NoContent;