import React from 'react'
import { useNavigate } from 'react-router-dom';
// import logo from '../assets/img/logo.svg';


function NotFound() {

    const navigate = useNavigate();

    return (
        <div className="not-found">
            {/* <img src={logo} alt='not found' /> */}
            <h1>404</h1>
            <p>This page might be under contruction or is not available but please keep calm and thanks for trying to access it</p>
            <button
                onClick={() => navigate('/')}
                className='btn'
            >
                Go back
            </button>
        </div>
    )
}

export default NotFound;
