import React from "react";
// import about from "../../assets/img/plexpark.jpg";
import about2 from "../../assets/img/plexpark3.jpg";


function AboutSection() {

    return (

        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="row position-relative mt-5">

                    <div className="col-lg-7">
                        <h2 className="about-title">About Multiplex Street Parking</h2>
                        <div className="our-story">
                            <h4>Established 2003</h4>
                            <h3>Our Background</h3>
                            <p>
                                {/* Multiplex Ltd. Was incorporated in 1984. It Is an indigenous Company that was established
                                with the aim of making a contribution towards the rehabilitation and transformation of war
                                ravaged economy of Uganda. The Company is involved in a wide range of business ventures
                                namely Construction, Quarrying, Hospitality, Real Estate and Street Parking Management.
                                <br />
                                <br />
                                Over the last 39 years, the company has grown leading to restructuring into a group of
                                companies engaged in Security Printing, IT Solutions, Hospitality, Construction, Transport,
                                Parking Management and control etc.
                                <br />
                                <br /> */}
                                Multiplex Street Parking started in a humble way in 2003 when it was contracted by Kampala
                                City Council (KCC) to manage and control Street Parking in Kampala. In 2010, Kampala Capital City Authority renewed the contract to Manage and Modernise Street Parking with
                                the objective of procuring & deploying the technologies below;
                            </p>

                            <ul>
                                <li><i className="bi bi-check-circle"></i> <span>Pay and Display parking meters.</span></li>
                                <li><i className="bi bi-check-circle"></i> <span>Hand held meters - Point of Sale Machines (POS).</span></li>
                                <li><i className="bi bi-check-circle"></i> <span>Automated Number plate recognition System.</span></li>
                                <li><i className="bi bi-check-circle"></i> <span>The parking devices were hiked to the central server which facilitated monitoring of parking operations.</span></li>
                            </ul>

                            <p>
                                In 2017, following a review of parking technologies in use, it was determined that P&D Meters
                                were not suitable due to low voluntary compliance among motorists and
                                poor network coverage leading to unstable connectivity. The ANPRS mounted on pick-ups
                                were also not very effective in capturing Motor vehicle number plates for vehicles parked bumper
                                to bumper.
                                <br />
                                <br />
                                In 2017/ 2018, KCCA revised the Contract and required that the parking management system in
                                use be revamped and realigned to the ideals of Smart City.
                                Currently we use the POS machines which are used for invoicing and also to detect defaulting
                                vehicles and notify Enforcement Personnel providing vehicle number and its physical location.
                            </p>

                            {/* <p>Vitae autem velit excepturi fugit. Animi ad non. Eligendi et non nesciunt suscipit repellendus porro in quo eveniet. Molestias in maxime doloremque.</p> */}

                            {/* <div className="watch-video d-flex align-items-center position-relative">
                                <i className="bi bi-play-circle"></i>
                                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox stretched-link">Watch Video</a>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-7 about-img about-img2" style={{ backgroundImage: `url(${about2})` }}></div>

                </div>

            </div>
        </section>

    );

}

export default AboutSection;
